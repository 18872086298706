.wander-list {
    @include wrapper();
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(320px, 1fr));
    grid-gap: 2rem;
    margin-top: 2rem;
    margin-bottom: 2rem;


  &__placeholder {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%)
  }

  &__title {
    font-size: 3rem;
    color: $brand-sea-green;
    margin: 0;
  }

  &__list {
    list-style-type: none;
    margin: 0;
    padding: 0;
  }

  &__item {
    display: flex;
    align-items: center;

    &:not(:last-child) {
      margin-bottom: 1rem;
    }

    &:last-child {

      .wander-item__panels {
        border-left: 4px solid transparent;
      }
    }
  }

}
