@import "settings/variables";
@import "settings/mixins";
@import "settings/animations";
@import "components/components";
@import "routes/routes";
@import "ember-power-select";

html, body {
  font-size: 16px;
  font-weight: 500;
  margin: 0;
  padding: 0;
  overflow-x: hidden;
}

html {

  &.no-overflow {
    overflow: hidden;
  }
}

body {
  font-family: Segoe UI, Helvetica, Arial, sans-serif;
  overflow-x: hidden;

  &.no-overflow {
    overflow: hidden;
    height: 100vh;
  }
}

textarea {
  font-family: Segoe UI, Helvetica, Arial, sans-serif;
}

* {
  box-sizing: border-box;
}

img, iframe, object, embed, button {
  vertical-align: middle;
}

button {
  border: none;
  background: none;
  cursor: pointer;
  font-size: 1rem;
}

.leaflet-bottom.leaflet-right {
  display: none;
}

.hidden-md {
  display: none !important;

  @media($screen-md, min) {
    display: initial;
  }
}

.username-sea-green {
  color: $brand-sea-green;
  font-weight: 400;
}

.username-yellow {
  color: $brand-yellow;
  font-weight: 400;
}

.main {

}

.sidebar {
  position: fixed;
  top: 60px;
  right: 0;
  z-index: 20;
  transform: translate(100%, 0);
  width: 350px;
  height: calc(100vh - 60px);
  overflow: scroll;
  background-color: white;
  border-left: 3px solid $brand-yellow-green;
  box-shadow: -4px 0px 10px 0px rgba(0, 0, 0, 0.2);
  box-sizing: content-box;
}

.container {
  @include wrapper;

  h1, h2, h3, h4, h5, h6 {
    color: $brand-sea-green;
  }
}

.divider {
  width: 100%;
  height: 1px;
  background-color: $brand-sea-green-lt;
  margin: 1rem 0;
}

.section {
  padding: 2rem 0;
  border-bottom: 1px solid $brand-yellow-green;

  &:first-of-type, &:last-of-type {
    border-bottom: none;
  }

  &__wrapper {
    @include wrapper();

    @include media($wrapper-max-width, max) {
      padding-left: 1rem;
      padding-right: 1rem;
    }
  }

  &_collapsed {

    .section-content {
      height: 0;
      overflow: hidden;
    }

    .section-header__toggle {
      transform: rotate(45deg) !important;
    }
  }

  .section-header {
    display: flex;
    justify-content: space-between;
    margin-bottom: 1rem;

    &__title {
      color: $brand-sea-green;
      margin: 0;
    }

    &__subtitle {
      font-size: 1.1rem;
      margin: 0;
    }

    &__toggle {
      color: $brand-yellow;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 48px;
      height: 48px;
      background-color: $brand-sea-green;
      border-radius: 50%;
      z-index: 10;
      transition: 0.3s ease background-color, 0.3s ease transform;
      border: 1px solid $brand-yellow;
      transform: rotate(0);

      &:hover {
        background-color: darken($brand-sea-green, 10);
      }
    }
  }

  .latest-guides-list {

    &__item {
      max-width: 450px;
    }

    &__wrapper {
      margin: 0;
    }

    .latest-guides-item {

      &__image {
        height: 320px;
      }

      &__label {
        font-size: 0.9rem;
      }
    }
  }

  .section-cta {
    display: flex;
    justify-content: center;
    align-items: center;

    &__link {
      color: $brand-sea-green;
      background-color: $brand-yellow;
      padding: 0.5rem 1rem;
      font-size: 1.2rem;
      font-weight: 600;
      text-decoration: none;
      transition: 0.3s ease background-color;

      &:hover {
        background-color: darken($brand-yellow, 10);
      }
    }
  }
}

.ember-power-select-multiple-option {
  display: flex;
}

.toastui-editor-defaultUI-toolbar {
  flex-wrap: wrap;
}

.emberTagInput {

  &-tag {
    white-space: nowrap;
    background-color: $brand-yellow;
  }

  &-input {
    padding-left: 5px;
    padding-right: 5px;
    margin-bottom: 0;
    width: 100%;
  }
}
