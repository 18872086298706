.book-panel {
  background-color: $brand-yellow;
  position: relative;
  width: 100%;
  height: 200px;
  margin-top: -200px;

  @include media($screen-sm, max) {
    display: none;
  }

  &__wrapper {
    @include wrapper();
    padding: 2rem 1rem;
    overflow: hidden;

    &::before {
      content: "";
      display: block;
      width: 4px;
      height: 100%;
      background-color: $brand-sea-green;
      position: absolute;
      top: 0;
      z-index: 5;
      transform: translateX(3rem);
    }

    &::after {
      content: "";
      display: block;
      width: 24px;
      height: 24px;
      background-color: $brand-sea-green;
      position: absolute;
      border-radius: 50%;
      top: 50%;
      z-index: 5;
      transform: translate(calc(3rem - 10px), -50%);
    }
  }

  &__form {
    display: grid;
    grid-template-areas: "fields actions"
    "options actions";
    grid-template-columns: 1fr 60px;
    grid-column-gap: 2rem;
    max-width: 900px;
    margin: 0 auto;
  }

  &__fields {
    grid-area: fields;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
    grid-gap: 1rem;
  }

  &__field {

    label {
      color: $brand-sea-green;
      font-weight: 600;
      margin-bottom: 5px;
    }

    input {
      width: 100%;
      outline: none;
      padding: 3px 5px;
      border: 1px solid $brand-sea-green;
      font-size: 1.2rem;
    }
  }

  &__options {
    grid-area: options;
    display: flex;
    justify-content: space-between;
    margin-top: 1rem;

    &::before {
      color: $brand-sea-green;
      content: "How:";
      display: flex;
      align-items: center;
      font-size: 1.2rem;
      font-weight: 600;
    }
  }

  &__option {
    display: flex;
    flex-direction: column;
    width: 40px;
    height: 53px;
    border: none;
    padding: 0;
    cursor: pointer;
    background: none;

    svg {
      height: 50px;
      min-height: 25px;
      width: 100%;
    }
  }

  &__actions {
    grid-area: actions;
    align-self: center;
  }
}
