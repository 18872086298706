&.place {
  grid-template-rows: 60px 1fr 160px;
  height: auto;

  .share-panel {

    &__wrapper {
      max-width: 900px;
    }
  }

  .footer {
    min-height: 60px;
  }
}
