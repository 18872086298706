.latest-places-item {
  display: grid;
  grid-template-areas: "label wrapper";
  grid-template-columns: min-content 1fr;
  position: relative;
  z-index: 10;
  margin-right: 1rem;

  &__label {
    grid-area: label;
    display: flex;
    flex-grow: 1;
    flex-shrink: 0;
    align-items: center;
    justify-content: center;
    color: $brand-sea-green;
    background-color: $brand-yellow;
    writing-mode: vertical-rl;
    text-orientation: mixed;
    transform: rotate(180deg);
    padding: 0.2rem;
    border-top: 1px solid lighten($brand-gray-md, 10);
    border-right: 1px solid lighten($brand-gray-md, 10);
    border-bottom: 1px solid lighten($brand-gray-md, 10);
    border-top-right-radius: 25px;
    border-bottom-right-radius: 25px;
    height: 100%;
  }

  &__wrapper {
    grid-area: wrapper;
    position: relative;
    color: $brand-sea-green;
    text-decoration: none;
    background-color: white;
    padding: 0.5rem;

    display: grid;
    grid-template-areas: "image info";
    grid-template-columns: 100px 1fr;
    grid-gap: 0.5rem;

    border: 1px solid lighten($brand-gray-md, 10);
    border-top-right-radius: 25px;
    border-bottom-right-radius: 25px;

    .map-box {
      width: 100%;
      height: 100%;

      .leaflet-container {
        width: 100%;
        height: 100%;
        min-width: 100px;
        min-height: 100px;
      }

      .leaflet-control-container {
        display: none;
      }
    }
  }

  &__image {
    grid-area: image;
    width: 100px;
    height: 100%;
    object-fit: cover;
  }

  &__info {
    grid-area: info;

  }

  .info {
    position: relative;
    z-index: 20;
    padding-left: 25px;

    &__title {
      color: $brand-sea-green;
      text-decoration: none;
      margin: 0;
      line-height: 1;
      font-weight: 400;
      font-size: 1.2rem;

      @include media($screen-sm, max) {
        font-size: 1rem;
      }
    }

    &__user-avatar {
      align-self: center;
      border-radius: 50%;
      position: absolute;
      top: 1.5rem;
      left: -40px
    }

    &__username {
      display: flex;
      align-items: center;
      margin: 0;
      line-height: 1;
      font-size: 1rem;

      @include media($screen-sm, max) {
        font-size: 0.9rem;
      }

      .user-follow-button {
        margin-left: 5px;
      }
    }

    &__category {
      display: inline-block;
      color: $brand-yellow;
      background-color: #5c5c5c;
      font-weight: 600;
      font-size: 0.7rem;
      padding: 0.2rem 0.5rem;

      ul {
        list-style: none;
        margin: 0;
        padding: 0;
      }
    }
  }

  &__controls {
    position: absolute;
    top: 50%;
    right: -1.3rem;
    transform: translateY(-50%);
  }

  &__control {
    color: $brand-yellow;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 48px;
    height: 48px;
    background-color: $brand-sea-green;
    border-radius: 50%;
    z-index: 20;
    transition: 0.3s ease background-color;
    border: 1px solid $brand-yellow;

    &:hover {
      background-color: darken($brand-sea-green, 10);
    }

    &_disabled {
      pointer-events: none;
      color: $brand-gray;
      background-color: $brand-gray-md;
      border: 1px solid $brand-gray;
    }
  }

  &__user {
    display: flex;
    margin-bottom: 0.5rem;
    align-items: center;

    img {
      border-radius: 50%;
      margin-right: 1rem;
    }

    h3 {
      margin: 0;
    }
  }
}
