&.wanderverse {
  @import "wanderverse/places/place";
  @import "wanderverse/guides/guide";
  @import "wanderverse/wanders/wander";
  @import "wanderverse/users/user";
  grid-template-rows: 60px 1fr 160px;

  .breadcrumbs-wrapper {
    padding: 0.3rem;

    .breadcrumbs {
      max-width: $wrapper-max-width;
      margin: 0 auto;
      font-size: 13px;
      font-weight: 500;
    }
  }

  .place-list {
    @include wrapper();
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
    grid-gap: 2rem;
    margin-top: 2rem;
  }

  .wanderwall-user-list {
    display: flex;

    @include media($screen-sm, max) {
      padding: 1rem 0;
    }

    &__item {
      //width: 108px;
    }
  }

  .footer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    grid-area: footer;
    margin-top: 2rem;

    &__title {
      color: $brand-yellow;
      font-size: 2rem;
      text-transform: uppercase;
      margin: 0;
    }

    &__copy {
      color: black;
      margin: 0;
    }
  }
}
