.latest-places-list {
  margin: 2rem 0;

  &__wrapper {
    @include wrapper;
  }

  &__list {
    @include wrapper();
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(320px, 1fr));
    grid-gap: 2rem;
    margin-top: 2rem;
    margin-bottom: 2rem;
    padding: 0;
    list-style: none;
  }
}
