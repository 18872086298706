.wanderilo-list-item {

  &:not(:last-child) {
    margin-bottom: 1rem;
  }

  &__wrapper {
    display: grid;
    grid-template-columns: 180px 1fr auto;
    grid-gap: 1rem;
  }

  &__image {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  &__description {

    h3 {
      color: $brand-sea-green;
      margin-top: 0;
    }
  }

  &__user-avatar {
    border-radius: 50%;
  }

  &__control-button {
    display: flex;
    color: #0f7a59ad;
    font-weight: 500;
    font-size: 0.8rem;
    line-height: 1;
    background-color: $brand-gray-lt;
    text-decoration: none;
    padding: 0.2rem 0.5rem;
    text-align: center;
    align-items: center;
    transition: 0.3s ease background-color;
    margin-right: 3px;
    margin-bottom: 3px;

    &:hover {
      background-color: darken($brand-gray-lt, 10);
    }

    .md-icon {
      margin-right: 0.5rem;
      flex-shrink: 0;
    }

    &_danger {
      //color: white;
      //background-color: $brand-red;
      color: #B3A8A8;
      background-color: #EFEFEF;

      &:hover {
        background-color: darken($brand-red, 10);
      }
    }

    @include media($screen-sm, min) {

      span.visible-sm {
        display: none;
      }
    }

    @include media($screen-sm, max) {
      padding: 0.2rem;
      flex-grow: 1;
      justify-content: center;

      .md-icon {
        margin-right: 0;
      }

      span {
        display: none;

        &.visible-sm {
          display: inline-block;
        }
      }
    }
  }
}
