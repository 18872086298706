@mixin media($size1, $size2: min) {
  @if $size2 == min {
    @media only screen and (min-width: $size1) {
      @content;
    }
  } @else if $size2 == max {
    @media only screen and (max-width: $size1 - 1) {
      @content;
    }
  } @else {
    @media only screen and (min-width: $size1) and (max-width: $size2 - 1) {
      @content;
    }
  }
}

@mixin wrapper($max-width: "") {
  @if $max-width != "" {
    width: 100%;
    max-width: $max-width;
    margin: 0 auto;

    @media only screen and (max-width: $max-width) {
      padding-right: 2rem;
      padding-left: 2rem;
    }

    @media only screen and (max-width: $screen-md) {
      padding-left: 1rem;
      padding-right: 1rem;
    }

    @media only screen and (max-width: $screen-sm) {
      padding-left: 0.5rem;
      padding-right: 0.5rem;
    }

  } @else {
    width: 100%;
    max-width: $wrapper-max-width;
    margin: 0 auto;

    @media only screen and (max-width: $wrapper-max-width) {
      padding-right: 2rem;
      padding-left: 2rem;
      max-width: 100%;
    }

    @media only screen and (max-width: $screen-md) {
      padding-left: 1rem;
      padding-right: 1rem;
    }

    @media only screen and (max-width: $screen-sm) {
      padding-left: 0.5rem;
      padding-right: 0.5rem;
    }
  }
}

@mixin button {
  border: none;
  background: none;
  cursor: pointer;
}
