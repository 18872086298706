.sortable-list {

  ol {
    margin: 0;
    padding: 0;
    list-style: none;
  }

  .sortable-item {
    border: 1px solid $brand-sea-green-lt;
    padding: 0.5rem;
    background-color: white;

    &:not(:last-child) {
      margin-bottom: 0.5rem;
    }

    &__main {
      display: flex;
      align-items: center;
    }

    &__preview {
      $preview-size: 50px;
      width: $preview-size;
      height: $preview-size;
      margin-right: 0.5rem;

      img, .map-box, .leaflet-container {
        width: $preview-size;
        height: $preview-size;
      }
    }

    &__title {
      font-weight: 500;
      flex-grow: 1;
      margin-right: 8px;
    }

    &__drag-handle {

      svg {
        width: 16px;
        height: 20px;
      }
    }

    &__delete-handle {
      background-color: $brand-sea-green;

      &:hover {
        background-color: darken($brand-sea-green, 10);
      }
    }

    &__drag-handle, &__delete-handle {
      display: flex;
      font-size: 1.4rem;
      color: $brand-yellow;
      font-weight: bold;
      cursor: pointer;
      padding: 3px;
      transition: 0.3s ease background-color;

      &:not(:last-child) {
        margin-right: 8px;
      }
    }

    &__dates-wrapper {
      display: flex;
      justify-content: space-between;
      margin-top: 5px;
      padding-top: 5px;
      border-top: 1px solid $brand-sea-green-lt;

      @include media($screen-sm, max) {
        flex-direction: column;
      }
    }

    &__date {

      @include media($screen-sm, max) {
        display: flex;

        span {
          display: inline-block;
          margin-right: 10px;
          width: 50px;
        }

        &:not(:last-child) {
          margin-bottom: 5px;
        }
      }
    }
  }
}
