.lifeline-item {
  flex-grow: 1;
  overflow: hidden;
  position: relative;
  z-index: 20;

  &__item {
    display: grid;
    grid-template-areas: "label wrapper view-button";
    grid-template-columns: min-content 1fr auto;
    flex-grow: 1;
    background-color: white;
    position: relative;
    z-index: 10;
  }

  &__label {
    grid-area: label;
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    background-color: $brand-sea-green;
    writing-mode: vertical-rl;
    text-orientation: mixed;
    transform: rotate(180deg);
    padding: 0.2rem;
    height: 100%;
  }

  &__wrapper {
    grid-area: wrapper;
    position: relative;
    color: rgba(23, 110, 13, 0.53);
    text-decoration: none;
    background-color: white;
    padding: 0.5rem;

    display: grid;
    grid-template-areas: "image card content controls";
    grid-template-columns: 100px 1fr auto 100px;
    grid-gap: 0.5rem;

    .map-box .leaflet-container {
      width: 100%;
      height: 100%;
      min-width: 100px;
      min-height: 100px;
    }
  }

  &__image {
    grid-area: image;
    width: 100px;
    height: 100px;
    object-fit: cover;
  }

  &__card {
    grid-area: card;

  }

  .lifeline-item-card {
    display: flex;

    &__title {
      margin: 0;
      line-height: 1;
    }

    &__user-avatar-wrapper {
      display: block;
      margin-left: -30px;
      margin-top: 1rem;
    }

    &__username {
      font-weight: 600;
    }

    &__categories {
      display: flex;

      ul {
        font-weight: 600;
        margin: 0 0 0 10px;
        padding-left: 1rem;
      }
    }
  }

  &__content {
    display: flex;
    flex-direction: column;
    grid-area: content;
  }

  .content {

    &__label {
      color: white;
      position: absolute;
      left: -0.5rem;
      top: -0.5rem;
      background-color: $brand-sea-green;
      padding: 0.1rem 1rem;
      border-radius: 0.2rem;
    }

    &__title {
      display: flex;
      align-items: center;
      margin: 0;
    }

    &__locations {
      display: flex;
      list-style: none;
      margin: 0;
      padding: 0;
      height: 100%;

      li {

        &:not(:last-child) {
          margin-right: 0.5rem;
        }
      }
    }
  }

  &__controls {
    grid-area: controls;
    display: flex;
    flex-direction: column;

    a, button {
      display: flex;
      color: $brand-sea-green;
      font-weight: 500;
      background-color: $brand-gray-lt;
      text-decoration: none;
      padding: 0.2rem 0.5rem;
      text-align: center;
      transition: 0.3s ease background-color;

      &:hover {
        background-color: darken($brand-gray-lt, 10);
      }

      .md-icon {
        margin-right: 0.5rem;
      }

      &:not(:last-child) {
        margin-bottom: 5px;
      }
    }
  }

  &__view-button {
    grid-area: view-button;
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    background-color: $brand-sea-green;
    writing-mode: vertical-rl;
    text-orientation: mixed;
    transform: rotate(180deg);
    padding: 0.2rem;
    text-decoration: none;
    text-transform: uppercase;
    font-weight: bold;
    transition: 0.3s ease background-color;

    &:hover {
      background-color: darken($brand-sea-green, 10);
    }
  }

  &__places {
    height: 0;
    overflow: hidden;
  }

  &__panels {
    grid-area: panels;
    padding: 0.5rem 0 0.5rem 1rem;
    margin-left: 3.3rem;
    margin-top: 1rem;
    position: relative;
    z-index: 10;

    .panels-box {
      transform: translateX(-44px);
    }

    .options-panel {

      &__control {
        display: flex;
        flex-direction: column;
        width: 20px;
        height: 33px;
        border: none;
        padding: 0;
        cursor: pointer;
        background: none;

        &:hover {
          background: none;
        }

        svg {
          height: 30px;
          min-height: 15px;
          width: 100%;
        }

        &:not(:last-child) {
          margin-right: 1rem;

          @include media($screen-sm, max) {
            margin-right: 10px;
          }
        }
      }
    }

    .location-panel {
      margin-bottom: 0.5rem;

      &__control {
        height: 50px;
        border: none;
        padding: 0;
        cursor: pointer;
        background: none;

        &:hover {
          background: none;
        }

        svg {
          height: 40px;
          width: 100%;
        }

        &:not(:last-child) {
          margin-right: 1rem;
        }
      }
    }

    .options-panel, .location-panel {
      position: relative;
      overflow: hidden;

      &__toggle {
        border: none;
        padding: 0;
        width: 50px;
        height: 50px;
        position: relative;
        z-index: 20;
        cursor: pointer;
        background: $brand-gray-lt;

        svg {
          width: 50px;
          height: 50px;
          object-fit: cover;
          transition: 0.3s ease all;
        }

        &_active {

          svg {
            transform: rotate(45deg);
          }
        }
      }

      &__content {
        display: flex;
        align-items: center;
        height: 50px;
        padding: 0 1rem;
        position: absolute;
        top: 0;
        left: 64px;
        z-index: 10;
        border: 1px solid $brand-sea-green-lt;
        justify-content: center;
        transform: translateX(-120%);
        transition: 0.3s ease transform;

        @include media($screen-sm, max) {
          padding: 0 10px;
        }

        &_opened {
          transform: translateX(0);
        }
      }
    }
  }

  .wander-item {
    display: grid;
    grid-template-areas: "label wrapper view-button";
    grid-template-columns: min-content 1fr auto;
    width: 100%;

    @include media($screen-sm, max) {
      grid-template-areas: "label wrapper"
                         "mobile-controls mobile-controls";
      grid-template-columns: min-content 1fr;
    }

    &__label {
      grid-area: label;
      display: flex;
      align-items: center;
      justify-content: center;
      color: white;
      background-color: $brand-sea-green;
      writing-mode: vertical-rl;
      text-orientation: mixed;
      transform: rotate(180deg);
      padding: 0.2rem;
      border-top: 1px solid lighten($brand-gray-md, 10);
      border-right: 1px solid lighten($brand-gray-md, 10);
      border-bottom: 1px solid lighten($brand-gray-md, 10);
      border-top-right-radius: 25px;
      border-bottom-right-radius: 25px;
      height: 100%;
    }

    &__wrapper {
      grid-area: wrapper;
      position: relative;
      color: $brand-sea-green;
      text-decoration: none;
      background-color: white;
      padding: 0.5rem;

      display: grid;
      grid-template-areas: "image info content controls";
      grid-template-columns: 100px 1fr auto 100px;
      grid-gap: 0.5rem;
      border: 1px solid lighten($brand-gray-md, 10);

      @include media($screen-sm, max) {
        grid-template-areas: "image info";
        grid-template-columns: 100px 1fr;
        border-top-right-radius: 25px;
        border-bottom-right-radius: 25px;
        padding: 0;
      }

      .map-box {

        .leaflet-container {
          width: 100%;
          height: 100%;
          min-width: 100px;
          min-height: 100px;
        }

        .leaflet-control-container {
          display: none;
        }
      }
    }

    &__image {
      grid-area: image;
      width: 100px;
      height: 100%;
      max-height: 120px;
      object-fit: cover;
    }

    &__card {
      grid-area: info;
    }

    .wander-item-card {
      display: flex;

      &__title {
        margin: 0;
        line-height: 1;
        font-weight:400;

        @include media($screen-sm, max) {
          font-size: 0.9rem;
        }
      }

      &__user-avatar-wrapper {
        display: block;
        margin-left: calc(-30px - 0.5rem);
        margin-top: 1rem;
        margin-right: 0.5rem;
      }

      &__username {
        font-weight: 300;
      }

      &__categories {
        display: inline-block;
        color: $brand-yellow;
        background-color: $brand-sea-green;
        font-weight: 600;
        font-size: 0.7rem;
        padding: 0.2rem 0.5rem;

        ul {
          list-style: none;
          margin: 0;
          padding: 0;
        }
      }

      &__tags {
        grid-area: tags;
        margin-top: 5px;
      }

      &__info {

        @include media($screen-sm, max) {
          padding: 0.5rem;
        }
      }
    }

    &__content {
      display: flex;
      flex-direction: column;
      grid-area: content;
    }

    .content {

      @include media($screen-sm, max) {
        display: none;
      }

      &__label {
        color: white;
        position: absolute;
        left: -0.5rem;
        top: -0.5rem;
        background-color: $brand-sea-green;
        padding: 0.1rem 1rem;
        border-radius: 0.2rem;
      }

      &__title {
        display: flex;
        align-items: center;
        margin: 0;
      }

      &__locations {
        display: flex;
        list-style: none;
        margin: 0;
        padding: 0;
        height: 100%;

        li {

          &:not(:last-child) {
            margin-right: 0.5rem;
          }
        }

        .map-box, .wander-item__image {
          height: 100%;
        }
      }
    }

    &__controls {
      grid-area: controls;
      display: flex;
      flex-direction: column;

      @include media($screen-sm, max) {
        display: none;
      }

      .wander-item-control-button {
        display: flex;
        color: $brand-sea-green;
        font-weight: 500;
        background-color: $brand-gray-lt;
        text-decoration: none;
        padding: 0.2rem 0.5rem;
        text-align: center;
        transition: 0.3s ease background-color;

        &:hover {
          background-color: darken($brand-gray-lt, 10);
        }

        &:not(:last-child) {
          margin-bottom: 5px;
        }

        .md-icon {
          margin-right: 0.5rem;
        }

        &_danger {
          color: white;
          background-color: $brand-red;

          &:hover {
            background-color: darken($brand-red, 10);
          }
        }
      }
    }

    &__mobile-controls {
      grid-area: mobile-controls;
      display: flex;
      justify-content: center;

      @include media($screen-sm, min) {
        display: none;
      }
    }

    &__mobile-control {
      display: flex;
      align-items: center;
      font-size: 0.7rem;
      font-weight: 600;
      padding: 2px 8px;
      border-left: 1px solid lighten($brand-gray-md, 10);
      border-right: 1px solid lighten($brand-gray-md, 10);
      border-bottom: 1px solid lighten($brand-gray-md, 10);
      border-bottom-left-radius: 10px;
      border-bottom-right-radius: 10px;
      overflow: hidden;
      height: 25px;

      &:not(:last-child) {
        margin-right: 3px;
      }

      &_primary {
        display: flex;
        color: $brand-sea-green;
        font-weight: 500;
        text-decoration: none;
        text-align: center;
        transition: 0.3s ease background-color;

        &:hover {
          background-color: transparent;
          color: darken($brand-sea-green, 10);
        }
      }

      &_danger {
        display: flex;
        justify-content: center;
        align-items: center;
        min-width: auto;
        background-color: transparent;
        color: $brand-red;

        .md-icon {
          margin: 0;
        }

        &:hover {
          background-color: transparent;
          color: darken($brand-red, 10);
        }
      }

      svg {
        flex-shrink: 0;
        flex-grow: 1;
      }
    }

    &__user {
      display: flex;
      margin-bottom: 0.5rem;
      align-items: center;

      img {
        border-radius: 50%;
        margin-right: 1rem;
      }

      h3 {
        margin: 0;
      }
    }

    &__view-button {
      grid-area: view-button;
      display: flex;
      align-items: center;
      justify-content: center;
      color: white;
      background-color: $brand-sea-green;
      writing-mode: vertical-rl;
      text-orientation: mixed;
      transform: rotate(180deg);
      padding: 0.2rem;
      text-decoration: none;
      text-transform: uppercase;
      font-weight: bold;
      transition: 0.3s ease background-color;

      border-top: 1px solid lighten($brand-gray-md, 10);
      border-left: 1px solid lighten($brand-gray-md, 10);
      border-bottom: 1px solid lighten($brand-gray-md, 10);

      border-top-left-radius: 25px;
      border-bottom-left-radius: 25px;

      @include media($screen-sm, max) {
        display: none;
      }

      &:hover {
        background-color: darken($brand-sea-green, 10);
      }
    }
  }
}
