.wander-editor {
  padding: 1rem 0;
  overflow: hidden;
  position: relative;
  z-index: 10;

  &__wrapper {
    @include wrapper(1100px);
  }

  &__top-bar {
    display: grid;
    grid-template-columns: 1fr 1fr;
    margin: 0 0 1rem 0;
  }

  &__title {
    color: $brand-sea-green;
    display: inline-block;
    margin: 0;
    align-self: center;
    line-height: 1;

    @include media($screen-sm, max) {
      font-size: 1.8rem;
    }
  }

  &__wander-title {
    margin-bottom: 1rem;
  }

  &__module {

    &:not(:last-child) {
      margin-bottom: 0.5rem;
    }
  }

  .place-editor {
    margin: 0;
    padding: 0;

    .pe-datepicker {
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-gap: 1rem;
    }

    &__geocoder {
      margin-top: 1rem;
    }
  }

  .wander-editor-datepicker {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 1rem;

    @include media($screen-sm, max) {
      grid-template-columns: 1fr;
      margin-top: 1rem;
    }

    &__label {
      min-width: 80px;
    }

    &__date {

      @include media($screen-sm, max) {
        display: flex;
      }
    }
  }

  .database-lookup-module {

    &__controls {
      margin-top: 1rem;

      button {
        @include button;
        display: flex;
        align-items: center;
        color: black;
        font-size: 1.2rem;
        background-color: $brand-yellow;
        padding: 0.5rem 2rem;
        transition: 0.3s ease background-color;
        margin-left: auto;

        svg {
          color: $brand-sea-green;
          margin-right: 10px;
        }

        &:hover {
          background-color: darken($brand-yellow, 10);
        }
      }
    }
  }

  .wander-editor-entries-list {
    list-style: none;
    margin: 0;
    padding: 0;
    position: relative;

    &::before {
      content: "";
      display: block;
      width: 3px;
      height: 200vh;
      background-color: $brand-sea-green;
      position: absolute;
      left: 50px;
      z-index: 1;

      @include media($screen-md, max) {
        left: 42px;
      }
    }

    &__sorter {
      color: white;
      font-weight: bolder;
      margin: 1rem 0 1rem 2rem;
      padding: 0;
      list-style: none;
      display: inline-flex;
      background-color: $brand-sea-green;
      position: relative;
      z-index: 2;

      @include media($screen-md, max) {
        margin: 1rem 0 1rem 1.5rem;
      }

      li {
        display: flex;
        align-items: center;
        cursor: pointer;
        padding: 5px 10px;

        &:hover, &.active {
          background-color: darken($brand-sea-green, 10);
        }
      }
    }

    &__item {
      display: flex;
      align-items: center;
      position: relative;
      z-index: 2;

      &:not(:last-child) {
        margin-bottom: 1rem;
      }
    }

    &__reorder-button {
      cursor: pointer;

      @include media($screen-md, max) {
        margin-right: 0.5rem;
      }

      svg {
        width: 20px;
        height: 50px;
      }
    }
  }

  &__controls {

    button {
      @include button;
      display: flex;
      align-items: center;
      color: black;
      font-size: 1.2rem;
      background-color: $brand-yellow;
      padding: 0.5rem 2rem;
      transition: 0.3s ease background-color;
      margin-left: auto;

      @include media($screen-sm, max) {
        font-size: 1rem;
        padding: 0.5rem 1rem;
      }

      svg {
        color: $brand-sea-green;
        margin-right: 10px;
      }

      &:hover {
        background-color: darken($brand-yellow, 10);
      }
    }
  }

  &__message-box {
    margin: 5rem 0;
  }

  &__message {
    color: $brand-sea-green;
    text-align: center;
    font-size: 1.1rem;
    font-weight: 600;
  }
}
