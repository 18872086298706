.lifeline-manager {
  position: relative;
  z-index: 10;
  display: flex;

  &__list {
    width: 100%;
    max-width: $wrapper-max-width;
    margin: 0 auto;
    padding: 2rem 1rem;
    position: relative;

    &::before {
      content: "";
      display: block;
      width: 4px;
      height: 100vh;
      background-color: $brand-sea-green;
      position: fixed;
      z-index: 5;
      transform: translate(3rem, -5rem);
    }
  }
}
