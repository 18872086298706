&.wai {
  grid-template-rows: 60px 1fr 160px;

  .header {

    .navigation {

      &__link {
        cursor: pointer;
        text-decoration: none;
      }
    }
  }

  .lifeline-footer {
    background-color: $brand-sea-green;
    padding: 5rem;
    margin-top: 2rem;
    margin-bottom: 5rem;
    border-radius: 2rem;
    position: relative;
    z-index: 20;

    @include media($screen-sm, max) {
      padding: 2rem;
    }

    &__intro {
      grid-area: intro;
      text-align: center;
      color: white;
      font-size: 1.2rem;
      font-weight: bolder;
      max-width: 800px;
      margin: 0 auto;

      h2 {
        color: $brand-yellow;
        font-size: 1.8rem;
        margin-top: 0;
      }
    }

    &__wrapper {
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-gap: 3rem;
      max-width: 800px;
      margin: 0 auto;

      @include media($screen-sm, max) {
        grid-template-columns: 1fr;
      }
    }

    .card {
      background-color: white;

      &__title {
        color: $brand-sea-green;
        text-align: center;
        background-color: $brand-yellow;
        text-transform: uppercase;
        font-size: 2rem;
        margin: 0;
        padding: 1rem;
      }

      &__subtitle {
        color: $brand-sea-green;
        text-align: center;
        background-color: $brand-gray-lt;
        text-transform: uppercase;
        font-size: 1.6rem;
        margin: 0;
        padding: 1rem;
      }

      &__list {
        margin: 0;
        padding: 2rem;
        list-style: none;
        color: $brand-sea-green;
        font-size: 1.2rem;
        font-weight: bolder;

        li {

          &:not(:last-child) {
            margin-bottom: 1rem;
          }
        }
      }
    }
  }

  .footer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    grid-area: footer;
    margin-top: 2rem;
    background-color: white;
    position: relative;
    z-index: 30;

    &__title {
      color: $brand-yellow;
      font-size: 2rem;
      text-transform: uppercase;
      margin: 0;
    }

    &__copy {
      color: black;
      margin: 0;
    }
  }
}
