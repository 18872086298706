.place-view {
  position: relative;
  z-index: 10;
  background-color : #F8F8F8;

  &__panel {
    position: relative;
    z-index: 30;
    width: 100%;
    background-color: white;
    padding: 0;

    @include media($screen-sm, max) {
      padding: 0 0 1rem 0;
    }
  }

  &__map {

    .leaflet-map {
      height: 400px;
    }

    .leaflet-marker-icon {
      border-radius: 50%;
      border: 4px solid $brand-yellow;
    }
  }

  &__wrapper {
    @include wrapper(900px);
    display: flex;
    align-items: end;
    justify-content: space-between;

    @include media($screen-sm, max) {
      flex-direction: column;
      align-items: baseline;
      padding: 0;
    }
  }

  &__item {
    width: 100%;
    max-width: 900px;
    margin: 1rem auto 3rem auto;
    padding: 2rem 0;
    position: relative;

    @include media($screen-sm, max) {
      margin: 0.5rem auto 3rem auto;
      padding: 0 0.5rem;
    }

    &_inactive {
      pointer-events: none;
      opacity: 0.5;
    }
  }

  &__title-bar {
    display: grid;
    grid-template-areas: "user-avatar-link username-link"
                         "user-avatar-link title"
                         "user-avatar-link date"
                         "user-avatar-link category-tags";
    grid-column-gap: 1rem;
    margin-top: 1rem;
  }

  &__user-avatar-link {
    grid-area: user-avatar-link;
    align-self: center;
  }

  &__username-link {
    grid-area: username-link;
    color: $brand-sea-green;
    font-size: 0.8rem;
    font-weight: 600;
    text-decoration: none;
  }

  &__user-avatar {
    border-radius: 50%;
  }

  &__title {
    grid-area: title;
    color: $brand-sea-green;
    display: inline-block;
    margin: 0.5rem 0 0.5rem;
    align-self: start;
    line-height: 1;
  }

  &__date {
    grid-area: date;
    color: $brand-sea-green;
    font-size: 0.8rem;
    font-weight: 600;
  }

  &__category-n-tags-wrapper {
    grid-area: category-tags;
    margin: 10px 0;
  }

  &__category {
    display: inline-block;
    color: $brand-yellow;
    background-color: #5c5c5c;
    font-weight: 600;
    font-size: 0.7rem;
    padding: 0.2rem 0.5rem;
  }

  .place-view-item {
    position: relative;
    margin-bottom: 5rem;

    &__content {
      margin-top: 1rem;
    }

    &__preview {
      margin-bottom: 1rem;

      img {
        max-height: 500px;
        object-fit: cover;
      }

      .map-box {

        .leaflet-container {
          width: 100%;
          height: 300px;
        }

        .leaflet-touch-zoom {
          pointer-events: none;
        }
      }
    }

    &__chapter-wrapper {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }

    &__chapter {
      color: $brand-gray-md;
      font-size: 2rem;
      font-weight: 400;
      margin: 0;
    }

    &__controls {
      margin-left: 1rem;
    }

    &__control {
      color: $brand-yellow;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 40px;
      height: 40px;
      background-color: $brand-sea-green;
      border-radius: 50%;
      z-index: 10;
      transition: 0.3s ease background-color;
      padding: 0;

      &:hover {
        background-color: darken($brand-sea-green, 10);
      }
    }

    &__title {
      color: $brand-sea-green;
      font-size: 2rem;
      margin: 0;
      max-width: 80%;
      line-height: 2rem;
    }

    &__controls-list {
      margin: 1rem 0;
      padding: 0;
      list-style: none;
      display: flex;
      align-items: center;

      li {
        display: flex;
        align-items: center;
        color: $brand-sea-green;
        font-weight: 600;
        position: relative;

        a {
          color: $brand-sea-green;
        }

        &:not(:last-child) {
          margin-right: 0.5rem;
        }

        &:nth-child(2) {
          padding-right: 0.5rem;

          &::after {
            display: block;
            content: "";
            height: 20px;
            width: 2px;
            background-color: $brand-sea-green;
            position: absolute;
            top: 6px;
            right: 0;
          }
        }
      }

      .location-icon {
        width: 20px;
        height: 30px;
        margin-right: 0.5rem;

        path {
          fill: $brand-sea-green;
        }
      }
    }

    &__coordinates {

    }

    &__images {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
      grid-gap: 2rem;
      margin-top: 2rem;
    }

    &__description {

    }
  }
}
