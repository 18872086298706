.search-widget {
  margin-right: 1rem;

  &__input {
    border: none;
    outline: none;
    padding: 0.4rem 0.3rem;
    min-width: 280px;
    font-size: 1rem;

    @include media(1200px, max) {
      min-width: 180px;
      width: 100%;
    }
  }
}

.ember-power-select-selected-item {
  display: flex;
}

.search-widget-result-item {
  display: flex;
  align-items: center;
  padding: 3px 0;

  &__image {
    border-radius: 50%;
    margin-right: 5px;
  }

  &__content {
    font-size: 0.8rem;
    line-height: 1.2;
  }

  &__record-type {
    font-weight: bolder;
  }
}
