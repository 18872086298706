.wanderwall-item {
  display: grid;
  grid-template-columns: 1fr 1fr;
  border: 1px solid lighten($brand-gray-md, 20);
  border-radius: 25px;
  overflow: hidden;
  position: relative;
  z-index: 1;

  @include media($screen-sm, max) {
    grid-template-columns: 1fr;
  }

  &:not(:last-child) {
    margin-bottom: 1rem;
  }

  &__preview {

    .map-box {
      height: 100%;
      min-height: 300px;

      @include media($screen-sm, max) {
        height: 250px;
        min-height: 250px;
      }

      .leaflet-container {
        width: 100%;
        height: 100%;

        @include media($screen-sm, max) {
          height: 250px;
          min-height: 250px;
        }
      }
    }
  }

  &__info {
    color: $brand-sea-green;
    padding: 1rem 1rem 1rem 3rem;
    background-color: white;
    position: relative;

    @include media($screen-sm, max) {
      position: initial;
      padding: 1rem;
    }
  }

  &__author {
    display: flex;
    align-items: center;

    h3 {
      margin-right: 1rem;
    }

    .user-avatar {
      margin-right: 1rem;
    }
  }

  &__username {
    font-size: 1.2rem;
    font-weight: 600;
    color: $brand-sea-green;
    text-decoration: none;
    margin-right: 1rem;

    span {
      font-weight: 400;
    }
  }

  &__date {
    margin-top: 10px;
    color: $brand-sea-green;
    font-size: 0.9rem;
    font-weight: 700;
  }

  &__title {
    margin: 0;
    color: $brand-sea-green;
    font-size: 1.4rem;
    font-weight: 600;
    text-decoration: none;
  }

  &__address {
    margin-bottom: 20px;
  }

  &__controls {
    display: flex;
    flex-direction: column;
    position: absolute;
    bottom: 1rem;
    left: -1.5rem;

    @include media($screen-sm, max) {
      top: 1rem;
      right: 1rem;
      left: initial;
    }
  }

  &__control {
    color: $brand-yellow;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 48px;
    height: 48px;
    background-color: $brand-sea-green;
    border-radius: 50%;
    z-index: 10;
    transition: 0.3s ease background-color;
    padding: 0;

    &:hover {
      background-color: darken($brand-sea-green, 10);
    }
  }
}
