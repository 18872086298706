&.wander {

  .share-panel {

    &__wrapper {
      max-width: 900px;
    }
  }

  .footer {
    margin-top: 0;
  }
}
