.publish-panel {
  position: relative;
  background-color: white;

  &__wrapper {
    max-width: 900px;
    margin: 0 auto;
  }

  &__buttons-wrapper {
    display: grid;
    grid-template-columns: 1fr 1fr;
  }

  &__control {
    color: $brand-sea-green;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    text-decoration: none;
    font-size: 1.2rem;
    font-weight: 400;
    padding: 0.4rem;
    border: 1px solid $brand-gray-md;
    height: 44px;
    transition: 0.3s ease background-color;

    &:first-child {
      border-right: 0;
    }

    &_active {
      background-color: $brand-yellow;
      font-weight: 600;
    }

    &:hover {
      background-color: $brand-sea-green;
      border: 1px solid $brand-sea-green;
      color: $brand-yellow
    }
  }

  .publish-panel-dropdown {
    background-color: $brand-sea-green;
    height: 0;
    overflow: hidden;
    z-index: 90;
    position: absolute;
    width: 100%;
    max-width: 900px;

    &__wrapper {
      padding: 1rem;
    }

    &__header {
      color: white;
      font-size: 1.4rem;
      font-weight: 400;
      text-decoration: none;
      line-height: 1.4rem;
      margin: 0;

      span {
        color: $brand-yellow;
        font-weight: bold;
      }
    }

    &__options {
      list-style: none;
      margin: 1rem 0 0 0;
      padding: 0;
    }

    .publish-panel-dropdown-option {
      background-color: white;
      border-radius: 10px;
      display: flex;
      align-items: center;

      &_wanderwall {

        &.shared {
          background-color: $brand-yellow;
        }

        .publish-panel-dropdown-option__content {
          display: grid;
          grid-template-areas: "title followers"
                               "description followers";
          grid-template-columns: 1fr min-content;
          grid-column-gap: 1rem;
        }

        .publish-panel-dropdown-option__title {
          grid-area: title;
        }

        .publish-panel-dropdown-option__description {
          grid-area: description;
        }

        .wanderwall-followers-list {
          display: flex;
          align-items: center;
          grid-area: followers;

          .user-avatar {

            &__image {
              border: 1px solid $brand-sea-green;

              @include media($screen-sm, max) {
                width: 50px;
                height: 50px;
              }
            }

            &:not(:first-child) {
              margin-left: - 30px;
            }
          }
        }
      }

      &_wanderverse {

        &.published {
          background-color: $brand-yellow;
        }
      }

      &:not(:last-child) {
        margin-bottom: 0.5rem;
      }

      &__tick-wrapper {
        padding: 1rem;

        @include media($screen-sm, max) {
          padding: 0.5rem;
        }
      }

      &__tick {
        width: 24px;
        height: 24px;
      }

      &__content {
        padding: 0.5rem;
        flex-grow: 1;
      }

      &__title {
        margin: 0;
        color: $brand-gray;
        font-weight: 600;

        span {
          color: $brand-sea-green;
          font-weight: 800;
        }
      }

      &__action-button-wrapper {
        padding: 1rem;
        display: flex;
        align-items: flex-start;
        justify-content: center;

        @include media($screen-sm, max) {
          padding: 0.5rem;
        }
      }

      &__action-button {
        padding: 0;

        img {
          width: 24px;
          height: 39px;
        }

        svg {
          color: $brand-sea-green;
        }
      }

      &__description {
        margin: 0;
      }
    }

    &__info {
      color: $brand-yellow;
      font-weight: 600;
      text-align: center;
    }
  }
}

.publish-panel-target {
  font-size: 0.9rem;
  padding: 0.2rem 0;

  &__wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &__status {
    color: black;
    background-color: $brand-yellow;
    border-radius: 5px;
    font-size: 0.7rem;
    padding: 0.1rem 0.3rem;
  }
}
