.place-item-list-view {
  background-color: white;

  &__wrapper {
    display: flex;
    padding: 0.5rem;
  }

  &__avatar-box {
    display: flex;
    margin-right: 1rem;

    .user-avatar {
      display: flex;
      align-items: center;
    }
  }

  &__first-letter {
    grid-area: first-letter;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0.5rem;

    > a {
      text-decoration: none;
    }

    .first-letter {
      font-size: 1rem;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 1.5rem;
      height: 1.5rem;
      background-color: $brand-sea-green;
      color: $brand-yellow;
      font-weight: 800;
      border-radius: 50%;
      transition: 0.3s ease transform;

      &:hover {
        transform: scale(1.4)
      }
    }
  }

  &__content-box {
    flex-grow: 1;
  }

  &__top-row {
    display: flex;

    @include media($screen-sm, max) {
      flex-direction: column;
    }
  }

  &__place-link {
    margin: 0;
    font-size: 1.1rem;
    padding-top: 5px;
    display: block;
    color: $brand-sea-green;
    text-decoration: none;
    font-weight: 500;
    flex-grow: 1;

    @include media($screen-sm, max) {
      line-height: 1.2;
      font-size: 0.9rem;
      order: 2;
    }
  }

  &__controls {
    order: 2;

    @include media($screen-sm, max) {
      order: 1;
    }
  }

  &__controls-wrapper {
    display: flex;
    justify-content: flex-end;

    .place-item-list-view-control-button {
      display: flex;
      color: #0f7a59ad;
      font-weight: 500;
      font-size: 0.8rem;
      line-height: 1;
      background-color: $brand-gray-lt;
      text-decoration: none;
      padding: 0.2rem 0.5rem;
      text-align: center;
      align-items: center;
      transition: 0.3s ease background-color;
      margin-right: 3px;
      margin-bottom: 3px;

      &:hover {
        background-color: darken($brand-gray-lt, 10);
      }

      .md-icon {
        margin-right: 0.5rem;
        flex-shrink: 0;
      }

      &_danger {
        //color: white;
        //background-color: $brand-red;
        color: #B3A8A8;
        background-color: #EFEFEF;

        &:hover {
          background-color: darken($brand-red, 10);
        }
      }

      @include media($screen-sm, min) {

        span.visible-sm {
          display: none;
        }
      }

      @include media($screen-sm, max) {
        padding: 0.2rem;
        flex-grow: 1;
        justify-content: center;

        .md-icon {
          margin-right: 0;
        }

        span {
          display: none;

          &.visible-sm {
            display: inline-block;
          }
        }
      }
    }

  }

  &__bottom-row {
    display: flex;
    align-items: center;
  }

  &__username-link {
    display: block;
    color: $brand-sea-green;
    text-decoration: none;
    font-size: 1rem;
    font-weight: 500;

    @include media($screen-sm, max) {
      font-size: 0.9rem;
      display: none;
    }
  }

  &__category-n-tags-wrapper {
    padding: 0.2rem 1rem;
    display: flex;
    background-color: white;
    width: 100%;

    @include media($screen-sm, max) {
      padding-left: 0;
    }
  }

  &__category {
    white-space: nowrap;
    color: white;
    background-color: #5c5c5c;
    font-size: 0.8rem;
    padding: 0.2rem 0.5rem;
    border-radius: 0.2rem;
    margin: 5px 5px 5px 0;

    @include media($screen-sm, max) {
      font-size: 11px;
    }
  }

  &__tags {
    width: calc(100% - 2rem);
    margin: 0;

    @include media($screen-sm) {
      overflow: hidden;
    }

    .tags-list {
      justify-content: center;
      white-space: nowrap;

      @include media($screen-sm) {
        animation: scrollText 20s linear 3s infinite;
        overflow: visible;
      }

      &__tag {
        font-size: 0.8rem;

        @include media($screen-sm, max) {
          font-size: 11px;
        }
      }

      @include media($screen-sm, max) {
        max-width: 100%;
      }
    }
  }
}

.place-item {
  position: relative;
  color: $brand-sea-green;
  text-decoration: none;
  flex-grow: 1;

  &__wrapper {

  }

  &__content {

  }

  &__user-avatar {
    border-radius: 50%;
  }

  .btn {

    &_follow {
      color: white;
      display: flex;
      align-items: center;
      background-color: $brand-sea-green;
      padding: 2px 10px;

      svg {
        margin-right: 5px;
      }
    }
  }

  .content {

    &__title {
      display: flex;
      align-items: center;
      margin: 0 0 0.5rem 0;
      line-height: 1;
    }

    &__description {
      font-size: 0.8rem;
    }
  }

  &__user {
    display: flex;
    margin-bottom: 0.5rem;
    align-items: center;

    img {
      border-radius: 50%;
      margin-right: 1rem;
    }

    h3 {
      margin: 0;
    }
  }

  &__tags {
    font-size: 0.7rem;
    display: flex;
    list-style-type: none;
    margin: 5px 0;
    padding: 0;
    overflow: hidden;

    @include media($screen-sm, max) {
      max-width: 150px;
      overflow-x: scroll;
    }
  }

  &__tag {
    color: black;
    font-weight: 500;
    background-color: $brand-yellow;
    padding: 0.2rem 0.5rem;
    border-radius: 0.2rem;
    flex-shrink: 0;
    white-space: nowrap;

    &:not(:last-child) {
      margin-right: 0.2rem;
    }
  }

  &__controls {
    grid-area: controls;
  }

  &__controls-wrapper {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-end;

    .place-item-control-button {
      display: flex;
      color: $brand-sea-green;
      font-weight: 500;
      font-size: 0.8rem;
      line-height: 1;
      background-color: $brand-gray-lt;
      text-decoration: none;
      padding: 0.2rem 0.5rem;
      text-align: center;
      align-items: center;
      transition: 0.3s ease background-color;
      margin-right: 3px;
      margin-bottom: 3px;

      &:hover {
        background-color: darken($brand-gray-lt, 10);
      }

      .md-icon {
        margin-right: 0.5rem;
        flex-shrink: 0;
      }

      &_danger {
        color: white;
        background-color: $brand-red;

        &:hover {
          background-color: darken($brand-red, 10);
        }
      }

      @include media($screen-sm, min) {

        span.visible-sm {
          display: none;
        }
      }

      @include media($screen-sm, max) {
        padding: 0.2rem;

        .md-icon {
          margin-right: 0;
        }

        span {
          display: none;

          &.visible-sm {
            display: inline-block;
          }
        }
      }
    }

  }

  &__mobile-controls {
    grid-area: mobile-controls;
    display: flex;
    justify-content: center;
    background-color: white;

    @include media(550px, min) {
      display: none;
    }
  }

  &__mobile-control {
    //display: flex;
    //align-items: center;
    //font-size: 0.7rem;
    //font-weight: 600;
    //padding: 2px 8px;
    //border-left: 1px solid lighten($brand-gray-md, 10);
    //border-right: 1px solid lighten($brand-gray-md, 10);
    //border-bottom: 1px solid lighten($brand-gray-md, 10);
    //border-bottom-left-radius: 10px;
    //border-bottom-right-radius: 10px;
    //overflow: hidden;
    //height: 25px;
    display: flex;
    color: $brand-sea-green;
    font-weight: 500;
    font-size: 0.8rem;
    line-height: 1;
    background-color: $brand-gray-lt;
    text-decoration: none;
    padding: 0.2rem 0.5rem;
    text-align: center;
    align-items: center;
    transition: 0.3s ease background-color;
    margin-right: 3px;
    margin-bottom: 3px;

    &:not(:last-child) {
      margin-right: 3px;
    }

    &_primary {
      display: flex;
      color: $brand-sea-green;
      font-weight: 500;
      text-decoration: none;
      text-align: center;
      transition: 0.3s ease background-color;

      &:hover {
        background-color: transparent;
        color: darken($brand-sea-green, 10);
      }
    }

    &_danger {
      display: flex;
      justify-content: center;
      align-items: center;
      min-width: auto;
      color: $brand-red;

      .md-icon {
        margin: 0;
      }

      &:hover {
        color: darken($brand-red, 10);
      }
    }

    svg {
      flex-shrink: 0;
      flex-grow: 1;
    }
  }

  &__category-n-tags-wrapper {
    padding: 0.2rem 1rem;
    border-top: 1px solid $brand-gray-lt;
    display: flex;
    background-color: white;
  }

  &__category {
    white-space: nowrap;
    color: white;
    background-color: #5c5c5c;
    font-size: 0.8rem;
    padding: 0.2rem 0.5rem;
    border-radius: 0.2rem;
    margin: 5px 5px 5px 0;

    @include media($screen-sm, max) {
      font-size: 11px;
    }
  }

  &__tags {
    width: calc(100% - 2rem);
    //overflow: hidden;
    margin: 0;

    .tags-list {
      justify-content: center;
      white-space: nowrap;
      //animation: scrollText 20s linear 3s infinite;
      //overflow: visible;

      &__tag {
        font-size: 0.8rem;

        @include media($screen-sm, max) {
          font-size: 11px;
        }
      }

      @include media($screen-sm, max) {
        max-width: 100%;
      }
    }
  }

  &_info-view {
    background-color: white;

    .place-item__wrapper {
      display: grid;
      grid-template-areas: "preview content address info"
                           "preview content controls controls";
      grid-template-columns: 300px 1fr 250px 250px;
      grid-template-rows: 1fr auto;
      grid-gap: 1rem;
      position: relative;
      z-index: 10;
      padding: 0.5rem;
    }

    .place-item__preview {
      grid-area: preview;
    }

    .place-item__content {
      grid-area: content;
      display: flex;
      flex-direction: column;
    }

    .content {

      &__header {
        align-items: center;
      }
    }

    .place-item__address {
      grid-area: address;
      display: flex;
      flex-direction: column;
    }

    .address {

      &__address {
        flex-grow: 1;
      }
    }

    .place-item__info {
      align-self: center;
    }

    .info {
      grid-area: info;
      display: flex;
      flex-direction: column;
      height: 100%;

      &__tags {
        display: flex;
        flex-wrap: wrap;
        list-style-type: none;
        margin: 0;
        padding: 0;
      }

      &__tag {
        color: white;
        background-color: $brand-gray;
        padding: 0.2rem 0.5rem;
        margin-bottom: 0.2rem;
        border-radius: 0.2rem;

        &:not(:last-child) {
          margin-right: 0.2rem;
        }
      }
    }

    hr {
      height: 1px;
      border: none;
      background-color: $brand-gray-md;
    }

    .map-box .leaflet-container {
      width: 300px;
      height: 300px;
    }
  }

  &_image-view {
    background-color: white;

    .place-item__wrapper {
      display: grid;
      grid-template-areas: "image content";
      grid-template-columns: minmax(280px, 450px) 1fr;
      grid-gap: 1rem;
      padding: 0.5rem;
      border-radius: 0;

      @include media($screen-sm, max) {
        grid-template-areas: "image"
                             "content";
        grid-template-columns: 1fr;
      }

      .place-item__image {
        max-height: 350px;
        object-fit: cover;

        @include media($screen-sm, max) {
          max-height: 200px;
        }
      }

      .map-box {

        @include media($screen-sm, max) {
          height: 200px;
        }

        .leaflet-container {
          width: 100%;
          height: 100%;
          min-height: 250px;

          @include media($screen-sm, max) {
            height: 200px;
          }
        }
      }
    }

    .place-item__user {
      display: flex;

      a {
        color: $brand-sea-green;
        text-decoration: none;
        margin: 0 0 0.5rem 0;
        font-weight: 600;
        font-size: 1.1rem;

        @include media($screen-sm, max) {
          font-size: 0.9rem;
        }
      }
    }

    .place-item__title {
      font-size: 1.3rem;
      display: block;
      font-weight: bold;
      color: $brand-sea-green;
      text-decoration: none;
      margin: 0;

      @include media($screen-sm, max) {
        line-height: 1;
        font-size: 1rem;
      }
    }

    .place-item__description {
      flex-grow: 1;
      margin-bottom: 1rem;
    }

    .place-item__content {
      display: flex;
      flex-direction: column;
    }

    .place-item__controls-wrapper {
      display: flex;

      a, button {
        flex-grow: 1;

        &:not(:last-child) {
          margin-bottom: 0;
        }
      }
    }

    .place-item__controls {

      @include media($screen-md, max) {
        display: none;
      }
    }

    .place-item__mobile-controls {
      border-top: 1px solid lighten($brand-gray-md, 10);
      display: flex;

      @include media($screen-md, min) {
        display: none;
      }
    }
  }
}
