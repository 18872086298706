.guide-list {
  @include wrapper();
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(320px, 1fr));
  grid-gap: 2rem;
  margin-top: 2rem;
  margin-bottom: 2rem;

  &__placeholder {
    @include wrapper();
    min-height: 500px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }

  &__title {
    font-size: 3rem;
    color: $brand-sea-green;
    margin: 0;
  }

  &__list {
    list-style-type: none;
    margin: 0;
    padding: 0;

    @include media($screen-sm, max) {
      padding: 0 1rem;
    }
  }

  &__item {
    display: flex;
    align-items: center;
    max-width: 480px;

    &:not(:last-child) {
      margin-bottom: 1rem;
    }
  }

  &__navigation {
    position: relative;
    min-height: 100px;

    .swiper-button-prev, .swiper-button-next {
      color: $brand-sea-green;
      margin-top: 0;
      transform: translateY(-50%);
    }
  }
}
