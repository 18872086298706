.lifeline-list {

  &__placeholder {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%)
  }

  &__title {
    font-size: 3rem;
    color: $brand-sea-green;
    margin: 0;
  }

  &__list {
    list-style-type: none;
    margin: 0;
    padding: 0;
  }

  &__item {
    display: flex;
    align-items: center;

    &:not(:last-child) {
      margin-bottom: 1rem;
    }

    &:last-child {

      .lifeline-item__panels {
        border-left: 4px solid transparent;
      }
    }
  }

}
