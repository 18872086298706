// Fonts
@font-face {
  font-family: 'Nunito';
  src: url('/assets/fonts/Nunito/Nunito-BoldItalic.eot');
  src: url('/assets/fonts/Nunito/Nunito-BoldItalic.eot?#iefix') format('embedded-opentype'),
  url('/assets/fonts/Nunito/Nunito-BoldItalic.woff2') format('woff2'),
  url('/assets/fonts/Nunito/Nunito-BoldItalic.woff') format('woff'),
  url('/assets/fonts/Nunito/Nunito-BoldItalic.ttf') format('truetype'),
  url('/assets/fonts/Nunito/Nunito-BoldItalic.svg#Nunito-BoldItalic') format('svg');
  font-weight: bold;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Nunito';
  src: url('/assets/fonts/Nunito/Nunito-Bold.eot');
  src: url('/assets/fonts/Nunito/Nunito-Bold.eot?#iefix') format('embedded-opentype'),
  url('/assets/fonts/Nunito/Nunito-Bold.woff2') format('woff2'),
  url('/assets/fonts/Nunito/Nunito-Bold.woff') format('woff'),
  url('/assets/fonts/Nunito/Nunito-Bold.ttf') format('truetype'),
  url('/assets/fonts/Nunito/Nunito-Bold.svg#Nunito-Bold') format('svg');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Nunito';
  src: url('/assets/fonts/Nunito/Nunito-ExtraBold.eot');
  src: url('/assets/fonts/Nunito/Nunito-ExtraBold.eot?#iefix') format('embedded-opentype'),
  url('/assets/fonts/Nunito/Nunito-ExtraBold.woff2') format('woff2'),
  url('/assets/fonts/Nunito/Nunito-ExtraBold.woff') format('woff'),
  url('/assets/fonts/Nunito/Nunito-ExtraBold.ttf') format('truetype'),
  url('/assets/fonts/Nunito/Nunito-ExtraBold.svg#Nunito-ExtraBold') format('svg');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Nunito';
  src: url('/assets/fonts/Nunito/Nunito-ExtraBoldItalic.eot');
  src: url('/assets/fonts/Nunito/Nunito-ExtraBoldItalic.eot?#iefix') format('embedded-opentype'),
  url('/assets/fonts/Nunito/Nunito-ExtraBoldItalic.woff2') format('woff2'),
  url('/assets/fonts/Nunito/Nunito-ExtraBoldItalic.woff') format('woff'),
  url('/assets/fonts/Nunito/Nunito-ExtraBoldItalic.ttf') format('truetype'),
  url('/assets/fonts/Nunito/Nunito-ExtraBoldItalic.svg#Nunito-ExtraBoldItalic') format('svg');
  font-weight: bold;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Nunito';
  src: url('/assets/fonts/Nunito/Nunito-Regular.eot');
  src: url('/assets/fonts/Nunito/Nunito-Regular.eot?#iefix') format('embedded-opentype'),
  url('/assets/fonts/Nunito/Nunito-Regular.woff2') format('woff2'),
  url('/assets/fonts/Nunito/Nunito-Regular.woff') format('woff'),
  url('/assets/fonts/Nunito/Nunito-Regular.ttf') format('truetype'),
  url('/assets/fonts/Nunito/Nunito-Regular.svg#Nunito-Regular') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Nunito';
  src: url('/assets/fonts/Nunito/Nunito-SemiBold.eot');
  src: url('/assets/fonts/Nunito/Nunito-SemiBold.eot?#iefix') format('embedded-opentype'),
  url('/assets/fonts/Nunito/Nunito-SemiBold.woff2') format('woff2'),
  url('/assets/fonts/Nunito/Nunito-SemiBold.woff') format('woff'),
  url('/assets/fonts/Nunito/Nunito-SemiBold.ttf') format('truetype'),
  url('/assets/fonts/Nunito/Nunito-SemiBold.svg#Nunito-SemiBold') format('svg');
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Nunito';
  src: url('/assets/fonts/Nunito/Nunito-ExtraLight.eot');
  src: url('/assets/fonts/Nunito/Nunito-ExtraLight.eot?#iefix') format('embedded-opentype'),
  url('/assets/fonts/Nunito/Nunito-ExtraLight.woff2') format('woff2'),
  url('/assets/fonts/Nunito/Nunito-ExtraLight.woff') format('woff'),
  url('/assets/fonts/Nunito/Nunito-ExtraLight.ttf') format('truetype'),
  url('/assets/fonts/Nunito/Nunito-ExtraLight.svg#Nunito-ExtraLight') format('svg');
  font-weight: 200;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Nunito';
  src: url('/assets/fonts/Nunito/Nunito-MediumItalic.eot');
  src: url('/assets/fonts/Nunito/Nunito-MediumItalic.eot?#iefix') format('embedded-opentype'),
  url('/assets/fonts/Nunito/Nunito-MediumItalic.woff2') format('woff2'),
  url('/assets/fonts/Nunito/Nunito-MediumItalic.woff') format('woff'),
  url('/assets/fonts/Nunito/Nunito-MediumItalic.ttf') format('truetype'),
  url('/assets/fonts/Nunito/Nunito-MediumItalic.svg#Nunito-MediumItalic') format('svg');
  font-weight: 500;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Nunito';
  src: url('/assets/fonts/Nunito/Nunito-Medium.eot');
  src: url('/assets/fonts/Nunito/Nunito-Medium.eot?#iefix') format('embedded-opentype'),
  url('/assets/fonts/Nunito/Nunito-Medium.woff2') format('woff2'),
  url('/assets/fonts/Nunito/Nunito-Medium.woff') format('woff'),
  url('/assets/fonts/Nunito/Nunito-Medium.ttf') format('truetype'),
  url('/assets/fonts/Nunito/Nunito-Medium.svg#Nunito-Medium') format('svg');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Nunito';
  src: url('/assets/fonts/Nunito/Nunito-LightItalic.eot');
  src: url('/assets/fonts/Nunito/Nunito-LightItalic.eot?#iefix') format('embedded-opentype'),
  url('/assets/fonts/Nunito/Nunito-LightItalic.woff2') format('woff2'),
  url('/assets/fonts/Nunito/Nunito-LightItalic.woff') format('woff'),
  url('/assets/fonts/Nunito/Nunito-LightItalic.ttf') format('truetype'),
  url('/assets/fonts/Nunito/Nunito-LightItalic.svg#Nunito-LightItalic') format('svg');
  font-weight: 300;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Nunito';
  src: url('/assets/fonts/Nunito/Nunito-ExtraLightItalic.eot');
  src: url('/assets/fonts/Nunito/Nunito-ExtraLightItalic.eot?#iefix') format('embedded-opentype'),
  url('/assets/fonts/Nunito/Nunito-ExtraLightItalic.woff2') format('woff2'),
  url('/assets/fonts/Nunito/Nunito-ExtraLightItalic.woff') format('woff'),
  url('/assets/fonts/Nunito/Nunito-ExtraLightItalic.ttf') format('truetype'),
  url('/assets/fonts/Nunito/Nunito-ExtraLightItalic.svg#Nunito-ExtraLightItalic') format('svg');
  font-weight: 200;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Nunito';
  src: url('/assets/fonts/Nunito/Nunito-SemiBoldItalic.eot');
  src: url('/assets/fonts/Nunito/Nunito-SemiBoldItalic.eot?#iefix') format('embedded-opentype'),
  url('/assets/fonts/Nunito/Nunito-SemiBoldItalic.woff2') format('woff2'),
  url('/assets/fonts/Nunito/Nunito-SemiBoldItalic.woff') format('woff'),
  url('/assets/fonts/Nunito/Nunito-SemiBoldItalic.ttf') format('truetype'),
  url('/assets/fonts/Nunito/Nunito-SemiBoldItalic.svg#Nunito-SemiBoldItalic') format('svg');
  font-weight: 600;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Nunito';
  src: url('/assets/fonts/Nunito/Nunito-Light.eot');
  src: url('/assets/fonts/Nunito/Nunito-Light.eot?#iefix') format('embedded-opentype'),
  url('/assets/fonts/Nunito/Nunito-Light.woff2') format('woff2'),
  url('/assets/fonts/Nunito/Nunito-Light.woff') format('woff'),
  url('/assets/fonts/Nunito/Nunito-Light.ttf') format('truetype'),
  url('/assets/fonts/Nunito/Nunito-Light.svg#Nunito-Light') format('svg');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Nunito';
  src: url('/assets/fonts/Nunito/Nunito-Italic.eot');
  src: url('/assets/fonts/Nunito/Nunito-Italic.eot?#iefix') format('embedded-opentype'),
  url('/assets/fonts/Nunito/Nunito-Italic.woff2') format('woff2'),
  url('/assets/fonts/Nunito/Nunito-Italic.woff') format('woff'),
  url('/assets/fonts/Nunito/Nunito-Italic.ttf') format('truetype'),
  url('/assets/fonts/Nunito/Nunito-Italic.svg#Nunito-Italic') format('svg');
  font-weight: normal;
  font-style: italic;
  font-display: swap;
}



// Responsive Breakpoints

$screen-xs: 375px;
$screen-sm: 768px;
$screen-md: 992px;
$screen-lg: 1280px;
$screen-xl: 1920px;

$wrapper-max-width: 1100px;
$nav-breakpoint: 1080px;

// Colors:

$brand-sea-green: #0f7a59;
$brand-sea-green-lt: #368851;
$brand-sea-green-secondary: #0F8762;

$brand-yellow: #fbcc2b;
$brand-yellow-green: #90a845;

$brand-red: #a20505;

$brand-slate: #1a1a1a;
$brand-gray: #606060;
$brand-gray-md: #969696;
$brand-gray-lt: #f4f4f4;


