.wander-editor-entry {
  display: grid;
  grid-template-areas: "label wrapper";
  grid-template-columns: min-content 1fr;
  position: relative;
  z-index: 10;
  width: 100%;
  margin-left: 2rem;

  @include media($screen-md, max) {
    grid-template-areas: "label wrapper"
                         "mobile-controls mobile-controls";
  }

  &__label {
    grid-area: label;
    display: flex;
    align-items: center;
    justify-content: center;
    color: $brand-sea-green;
    background-color: $brand-yellow;
    writing-mode: vertical-rl;
    text-orientation: mixed;
    transform: rotate(180deg);
    padding: 0.2rem;
    border-top: 1px solid lighten($brand-gray-md, 10);
    border-bottom: 1px solid lighten($brand-gray-md, 10);
    border-right: 1px solid lighten($brand-gray-md, 10);
    //border-top-right-radius: 25px;
    //border-bottom-right-radius: 25px;
    height: 100%;
    flex-grow: 1;

    @include media($screen-sm, max) {
      font-size: 0.7rem;
      font-weight: 700;
    }
  }

  &__wrapper {
    color: $brand-sea-green;
    display: grid;
    grid-area: wrapper;
    grid-template-areas: "image info controls";
    grid-template-columns: 100px 1fr min-content;
    grid-gap: 1rem;
    flex-grow: 1;
    background-color: white;
    padding: 0.5rem;
    border: 1px solid lighten($brand-gray-md, 10);
    //border-top-right-radius: 25px;
    //border-bottom-right-radius: 25px;

    @include media($screen-md, max) {
      padding: 0;
      grid-template-areas: "image info";
      grid-template-columns: 100px 1fr;

      .wander-editor-entry__controls {
        display: none;
      }
    }
  }

  &__image {
    grid-area: image;
    width: 100px;
    height: 100%;
    max-height: 120px;
    object-fit: cover;
  }

  &__content {
    display: flex;
    flex-direction: column;
    grid-area: content;
  }

  &__info {
    grid-area: info;
  }

  &__controls {
    grid-area: controls;

    @include media($screen-md, max) {
      display: none;
    }
  }

  &__control {

    &_primary {
      display: flex;
      color: $brand-sea-green;
      font-weight: 500;
      background-color: darken($brand-gray-lt, 10);
      text-decoration: none;
      padding: 0.2rem 0.5rem;
      text-align: center;
      transition: 0.3s ease background-color;
      min-width: 110px;

      &:hover {
        background-color: darken($brand-gray-lt, 20);
      }

      .md-icon {
        margin-right: 0.5rem;
      }
    }

    &_danger {
      width: 24px;
      height: 24px;
      justify-content: center;
      align-items: center;
      min-width: auto;
      background-color: transparent;
      color: $brand-red;
      padding: 0;

      .md-icon {
        margin: 0;
      }

      &:hover {
        background-color: transparent;
        color: darken($brand-red, 10);
      }
    }

    &:not(:last-child) {
      margin-bottom: 5px;
    }
  }

  &__mobile-controls {
    grid-area: mobile-controls;
    display: flex;
    justify-content: flex-end;
    padding-right: 2rem;

    @include media($screen-md, min) {
      display: none;
    }
  }

  &__mobile-control {
    display: flex;
    align-items: center;
    padding: 0 8px;
    border-left: 1px solid lighten($brand-gray-md, 10);
    border-right: 1px solid lighten($brand-gray-md, 10);
    border-bottom: 1px solid lighten($brand-gray-md, 10);
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;

    &:not(:last-child) {
      margin-right: 3px;
    }

    &_primary {
      display: flex;
      color: $brand-sea-green;
      font-weight: 500;
      text-decoration: none;
      padding: 0.2rem 0.5rem;
      text-align: center;
      transition: 0.3s ease background-color;

      &:hover {
        background-color: transparent;
        color: darken($brand-sea-green, 10);
      }
    }

    &_danger {
      display: flex;
      justify-content: center;
      align-items: center;
      min-width: auto;
      background-color: transparent;
      color: $brand-red;

      .md-icon {
        margin: 0;
      }

      &:hover {
        background-color: transparent;
        color: darken($brand-red, 10);
      }
    }
  }

  .info {
    position: relative;
    z-index: 10;
    display: grid;
    grid-template-areas: "user-info timestamp-wrapper description tags"
                         "user-info title-wrapper description tags"
                         "user-info category-wrapper description tags";
    grid-template-columns: 100px 1fr 1fr 100px;
    grid-template-rows: min-content 1fr min-content;
    grid-column-gap: 0.5rem;

    @include media($screen-sm, max) {
      grid-template-columns: 60px 1fr;
      grid-template-areas: "user-info timestamp-wrapper"
                         "user-info title-wrapper"
                         "user-info category-wrapper"
                         "user-info tags";
      grid-template-rows: min-content 1fr min-content min-content;
      margin-left: calc(-40px - 1rem);
    }

    &__user-info {
      grid-area: user-info;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;

      @include media($screen-md, max) {
        justify-content: center;
        align-items: center;

        .info__username {
          display: none;
        }
      }
    }

    &__user-avatar {
      border-radius: 50%;
    }

    &__title-wrapper {
      grid-area: title-wrapper;
      margin: 0;
      line-height: 1;

      @include media($screen-md, min) {

        .info__username {
          display: none;
        }
      }
    }

    &__title {
      color: $brand-sea-green;
      font-size: 1.3rem;
      line-height: 1.4rem;
      font-weight: 700;
      margin: 0;
      word-wrap: break-word;
      text-decoration: none;

      @include media($screen-sm, max) {
        font-size: 0.9rem;
      }
    }

    &__username {
      font-size: 1rem;
      line-height: 1;
      margin: 0;
      font-weight: 600;

      @include media($screen-sm, max) {
        font-size: 0.9rem;
      }
    }

    &__timestamp-wrapper {
      grid-area: timestamp-wrapper;
      margin-top: -0.5rem;

      @include media($screen-md, max) {
        margin-top: 0;
        margin-left: -63px;
      }
    }

    &__timestamp {
      font-size: 0.8rem;
      display: table-cell;
      padding: 2px 10px 3px 10px;
      background-color: $brand-yellow;
      border-bottom-left-radius: 10px;
      border-bottom-right-radius: 10px;
      white-space: nowrap;

      input {
        font-size: 10px;
        border: none;
        outline: none;
        width: 80px;
      }

      @include media($screen-md, max) {
        font-size: 0.6rem;
      }
    }

    &__category-wrapper {
      grid-area: category-wrapper;
    }

    &__category {
      display: inline-block;
      color: white;
      background-color: #5c5c5c;
      font-weight: 600;
      font-size: 0.7rem;
      padding: 0.2rem 0.5rem;
    }

    &__description {
      grid-area: description;

      @include media($screen-sm, max) {
        display: none;
      }
    }

    &__tags {
      grid-area: tags;

      @include media($screen-md, max) {
        padding: 0.5rem;
      }

      @include media($screen-sm, max) {
        padding: 0.5rem 0;
      }
    }
  }

  .map-box {

    .leaflet-container {
      width: 100%;
      height: 100%;
    }

    .leaflet-control-container {
      display: none;
    }
  }
}
