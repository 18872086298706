&.spotlist {

  .footer {
    background-color: $brand-sea-green;

    &__copy {
      color: white;
    }
  }
}
