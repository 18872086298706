.lifeline-now {
  display: flex;
  align-items: center;
  margin-top: 2rem;
  margin-left: 0.15rem;

  &::before {
    content: "";
    display: block;
    width: 24px;
    height: 24px;
    border-radius: 50%;
    background-color: $brand-yellow;
    position: relative;
    z-index: 10;
    margin: 0 2.15rem;

    @include media($screen-sm, max) {
      margin: 0 1rem 0 2.15rem;
    }
  }

  &__wrapper {
    position: relative;

    .search-wander{
      visibility:hidden;
      position: absolute;
      left: 70px;
      padding-top: 12px;
      padding-bottom: 12px;
      padding-left:10px;
      font-size: 15px;
      width: 380px;
    }
  }

  &__line {
    margin-left: -34px;
    width: 30px;
    height: 4px;
    background-color: #0f7a59;
  }

  &__controls {
    display: inline-flex;
    padding: 1rem;
    background-color: $brand-yellow;
    border: 1px solid $brand-sea-green;
    border-radius: 0.5rem;

    @include media($screen-sm, max) {
      padding: 0.5rem;
    }
  }

  &__control {
    display: flex;
    flex-direction: column;
    width: 40px;
    height: 40px;
    border: none;
    padding: 0;
    cursor: pointer;
    background: none;

    @include media($screen-sm, max) {
      width: 15px;
      height: 15px;
    }

    &:hover {
      background: none;
    }

    svg {
      height: 40px;
      min-height: 15px;
      width: 100%;
    }

    img {
      width: 100%;
    }

    &:not(:last-child) {
      margin-right: 1rem;
    }
  }
}
