&.wanderwall {
  height: auto;

  .footer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    grid-area: footer;
    background-color: $brand-sea-green;
    margin-top: 2rem;
    min-height: 160px;

    &__title {
      color: $brand-yellow;
      font-size: 2rem;
      text-transform: uppercase;
      margin: 0;
    }

    &__copy {
      color: white;
      margin: 0;
    }
  }
}
