.wanderwall-manager {

  &__user-list {
    background-color: #E8E8E8;
  }

  &__items {
    width: 100%;
    max-width: $wrapper-max-width;
    margin: 0 auto;
    padding: 2rem 1rem;

    @include media($screen-sm, max) {
      padding: 2rem 0.5rem;
    }
  }
}
