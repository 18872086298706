.spotlist-view {
  padding: 0 0 2rem 0;
  background-color: white;

  .spotlist-user-profile {

    &__wrapper {
      display: grid;
      grid-template-areas: "content-stack top-bar";
      @include wrapper;
      padding: 2rem 0;
      grid-column-gap: 2rem;

      @include media ($screen-sm, max) {
        grid-template-areas: "content-stack"
                             "top-bar";
        padding-bottom: 0;
      }
    }

    &__top-bar {
      grid-area: top-bar;
      display: flex;
      flex-direction: column;
      align-items: center;

      @include media($screen-sm, max) {
        justify-content: center;
      }
    }

    &__content-stack {
      display: grid;
      grid-area: content-stack;
      grid-template-areas: "avatar-wrapper content";
      grid-template-columns: 120px 1fr;
      grid-gap: 2rem;

      @include media($screen-sm, max) {
        grid-template-areas: "avatar-wrapper"
                           "content";
        grid-template-columns: 1fr;
        margin-bottom: 1rem;
      }
    }

    &__top-bar {

    }

    &__save-spotlist-button {
      color: $brand-sea-green;
      font-size: 1.1rem;
      font-weight: 600;
      display: flex;
      align-items: center;
      border: 1px solid $brand-sea-green;
      border-radius: 0;
      padding: 5px 15px;
      text-decoration: none;
      transition: 0.3s ease all;

      &:hover {
        background-color: $brand-yellow;
        color: $brand-sea-green;

        .counter {
          color: $brand-yellow;
          background-color: $brand-sea-green;
        }
      }

      .counter {
        color: $brand-yellow;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 1.2rem;
        font-weight: 600;
        background-color: $brand-sea-green;
        border-radius: 50%;
        width: 30px;
        height: 30px;
        margin-left: 1rem;
        transition: 0.3s ease all;
      }
    }

    &__avatar-wrapper {
      grid-area: avatar-wrapper;
      color: $brand-sea-green;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      .subscribe-button {
        display: flex;
        align-items: center;
        justify-content: center;
        color: $brand-sea-green;
        background-color: $brand-yellow;
        font-weight: 600;
        margin-top: -1rem;
        min-width: 120px;

        &_active {
          color: $brand-yellow;
          background-color: $brand-sea-green;
        }
      }

      p {
        margin: 0;
        font-weight: 500;
      }
    }

    &__avatar {
      border-radius: 50%;
      object-fit: cover;
      grid-area: avatar;

      @include media($screen-sm, max) {
        margin-right: 0;
      }
    }

    &__content {
      grid-area: content;
      align-self: center;
      color: $brand-sea-green;
    }

    &__username {
      font-family: 'Nunito', sans-serif;
      margin: 0 0 1rem 0;
      line-height: 1;
      font-size: 2.5rem;
      font-weight: 500;

      @include media($screen-sm, max) {
        text-align: center;
      }
    }

    &__tagline {
      margin: 0;
      font-size: 1rem;
      line-height: 1.2;
      font-weight: 600;
    }

    &__category-n-tags-wrapper {
      padding: 0.2rem 0;
      display: flex;
      background-color: white;
      width: 100%;
      margin-top: 1rem;
    }

    &__category {
      white-space: nowrap;
      color: white;
      background-color: $brand-sea-green;
      font-size: 1rem;
      padding: 0.2rem 0.5rem;
      border-radius: 0.2rem;
      margin: 5px 5px 5px 0;
      font-weight: 600;

      @include media($screen-sm, max) {
        font-size: 11px;
      }
    }

    &__tags {
      width: calc(100% - 2rem);
      margin: 0;

      @include media($screen-sm, max) {
        overflow: hidden;
      }

      .tags-list {
        //justify-content: center;
        white-space: nowrap;

        @include media($screen-sm, max) {
          animation: scrollText 20s linear 3s infinite;
          overflow: visible;
        }

        &__tag {
          font-size: 1rem;
          font-weight: 600;
          background-color: $brand-yellow;

          @include media($screen-sm, max) {
            font-size: 11px;
          }
        }

        @include media($screen-sm, max) {
          max-width: 100%;
        }
      }
    }

    &__control {
      color: white;
      padding: 0.3rem 1.5rem;
      border-radius: 0.2rem;
      text-decoration: none;
    }
  }

  .spotlist-user-profile-map {
    position: relative;
    z-index: 1;

    .leaflet-map {
      height: 400px;
    }

    .leaflet-marker-icon {
      border-radius: 50%;
      border: 4px solid $brand-yellow;
    }
  }

  .spotlist-description {
    max-width: 600px;
    margin: 2rem auto;
    color: $brand-sea-green;

    &__title {
      color: $brand-sea-green;
      text-transform: uppercase;
      font-size: 2rem;
      text-align: center;
    }

    &__category-n-tags-wrapper {
      padding: 0.2rem 0;
      display: flex;
      background-color: white;
      width: 100%;
      margin-top: 1rem;
    }

    &__category {
      white-space: nowrap;
      color: white;
      background-color: $brand-sea-green;
      font-size: 0.8rem;
      padding: 0.2rem 0.5rem;
      border-radius: 0.2rem;
      margin: 5px 5px 5px 0;
      font-weight: 600;
    }

    &__tags {
      width: calc(100% - 2rem);
      overflow: hidden;

      .tags-list {
        justify-content: center;
        white-space: nowrap;
        animation: scrollText 20s linear 3s infinite;
        overflow: visible;

        &__tag {
          font-size: 0.8rem;
          font-weight: 600;
          background-color: $brand-yellow;
        }
      }
    }
  }

  .spotlist-book-cta {
    background-color: $brand-sea-green;
    padding: 1rem 0;

    &__wrapper {
      @include wrapper(600px);
    }

    &__button {
      border: 2px solid $brand-yellow;
      color: white;
      display: block;
      padding: 0.5rem 1rem;
      font-weight: 600;
      text-decoration: none;
      transition: 0.3s ease background-color;

      span {
        text-transform: uppercase;
        font-size: 1.3rem;
      }

      p {
        margin: 0;
        font-size: 0.8rem;
      }

      &:hover {
        background-color: darken($brand-sea-green, 5);
      }
    }
  }

  &__tabs-wrapper {
    background-color: $brand-sea-green;
    padding: 5px 0;

    .sup-tabs {
      max-width: 500px;
      margin: 0 auto;
      padding: 0;
      display: flex;
      list-style: none;

      .sup-tab {
        display: flex;
        align-items: center;
        flex-grow: 1;
        padding: 1rem;
        cursor: pointer;

        &:not(:last-child) {
          border-right: 2px solid $brand-yellow;
        }

        &:hover {
          background-color: darken($brand-sea-green, 10);
        }

        .sup-tab-counter {
          color: $brand-sea-green;
          display: flex;
          align-items: center;
          justify-content: center;
          font-size: 1.5rem;
          font-weight: 600;
          background-color: $brand-yellow;
          border-radius: 50%;
          width: 40px;
          height: 40px;
          margin-right: 1rem;
        }

        .sup-tab-label {
          display: flex;
          flex-direction: column;
          color: $brand-yellow;
          font-size: 1.4rem;
          font-weight: 600;
          line-height: 1;

          span {
            font-size: 0.8rem;
          }
        }
      }
    }
  }

  &__subscribe-cta {
    background-color: $brand-yellow;
    text-transform: uppercase;
    color: $brand-sea-green;
    text-align: center;
    padding: 0.5rem;
    font-weight: 600;
  }

  .spot-box {

    &__wrapper {
      @include wrapper(900px);
    }

    &__header {
      color: $brand-sea-green;
      display: flex;
      font-size: 2rem;
    }

    &__counter {
      color: $brand-yellow;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 1.7rem;
      font-weight: 600;
      background-color: $brand-sea-green;
      border-radius: 50%;
      width: 50px;
      height: 50px;
      margin-right: 1rem;
    }
  }

  .spotlist-view-section {
    @include wrapper(900px);

    &:not(:last-of-type) {
      margin-bottom: 4rem;
    }

    &__avatar {
      margin-right: 1rem;
      border-radius: 50%;
    }

    &__counter {
      color: $brand-yellow;
      background-color: $brand-sea-green;
      border-radius: 50%;
      width: 40px;
      height: 40px;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-right: 0.5rem;
    }

    &__title {
      font-size: 2rem;
      display: flex;
      align-items: center;
      color: $brand-sea-green;
    }
  }

  .spotlist-view-guides {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
    grid-gap: 2rem;

    @include media($screen-sm, max) {

      .wanderilo-guide-item {

        &:not(:first-of-type) {
          display: none;
        }
      }
    }
  }

  .spotlist-view-wanders {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
    grid-gap: 2rem;
    margin-bottom: 2rem;

    @include media($screen-sm, max) {

      .wanderilo-wander-item {

        &:not(:first-of-type) {
          display: none;
        }
      }
    }
  }
}

