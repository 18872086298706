&.spot {

  .hero {

    .leaflet-container {
      height: 200px;
      z-index: 10;
      pointer-events: none;

      .leaflet-control{
        display: none;
      }

      .leaflet-control-attribution {
        display: none;
      }
    }
  }

  .userbar {

    &__avatar {
      display: flex;
      justify-content: center;
      align-items: center;

      img {
        border-radius: 50%;
        border: 3px solid $brand-yellow;
      }

      .username-sea-green {
        font-size: 2rem;
      }
    }

    &__content {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
    }

    &__description {
      text-align: center;
      border-top: 1px solid $brand-gray-lt;
      margin-top: 0.5rem;
      padding-top: 0.5rem;
    }
  }
}
