.signup {
  display: grid;
  grid-template-areas: "header" "main" "footer";
  grid-template-rows: 60px 1fr 160px;
  height: 100vh;

  .header {
    grid-area: header;
  }

  .main {
    grid-area: main;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 2rem 0;
  }

  .footer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    grid-area: footer;
    background-color: $brand-sea-green;

    &__title {
      color: $brand-yellow;
      font-size: 2rem;
      text-transform: uppercase;
      margin: 0;
    }

    &__copy {
      color: white;
      margin: 0;
    }
  }
}
