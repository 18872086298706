&.index {
  grid-template-rows: 60px 1fr 80px;

  .footer {
    display: flex;
    align-items: center;
    grid-area: footer;
    background-color: $brand-sea-green;

    .alphabet-filter {
      display: flex;
      justify-content: space-between;
      margin: 0 auto;
      padding: 0;
      list-style-type: none;
      width: 100%;
      max-width: 800px;

      @include media($screen-sm, max) {
        display: none;
      }

      &__item {
        font-weight: 600;
        font-size: 1.4rem;
      }

      &__link {
        display: flex;
        align-items: center;
        color: $brand-yellow;
        text-decoration: none;

        &.active {
          color: darken($brand-sea-green, 10);
        }

        &_prev, &_next {
          background-color: darken($brand-sea-green, 10);
          padding: 0 1rem;
          justify-content: center;
        }

        &_all {
          border-right: 1px solid $brand-yellow;
          padding-right: 1rem;
        }
      }
    }
  }
}
