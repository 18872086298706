.alphabet-filter {
  display: flex;
  justify-content: space-between;
  margin: 0 auto;
  padding: 0;
  list-style-type: none;
  width: 100%;

  @include media($screen-sm, max) {
    display: none;
  }

  &__item {
    font-weight: 600;
    font-size: 1.4rem;

    @include media(1050px, max) {
      font-size: 1rem;
    }
  }

  &__link {
    display: flex;
    align-items: center;
    justify-content: center;
    color: $brand-yellow;
    text-decoration: none;
    width: 34px;
    height: 34px;
    border-radius: 50%;

    @include media(1050px, max) {
      width: 24px;
      height: 24px;
    }

    &:hover {
      background-color: $brand-sea-green;
    }

    &.active {
      color: darken($brand-sea-green, 10);
    }

    &_prev, &_next {
      background-color: #EFEFEF;
      padding: 0 0.5rem;
      justify-content: center;
      border-radius: 0;
      width: auto;
    }

    &_all {
      border-right: 1px solid $brand-yellow;
      padding-right: 1rem;
      border-radius: 0;
      width: auto;

      &:hover {
        background-color: transparent;
      }
    }
  }
}
