.editor-module {

  &_collapsed {

    .editor-module-header__toggle {
      transform: rotate(45deg) !important;
    }

    .editor-module-content {
      height: 0;
      //transition: 0.3s ease height;
    }
  }

  .editor-module-header {
    display: flex;
    justify-content: space-between;
    background-color: #F1F1F1;
    padding: 0.5rem 0.5rem 0.5rem 1rem;
    border-radius: 5px;

    &__title {
      color: $brand-sea-green;
      font-size: 1.2rem;
      font-weight: 300;
      margin: 0;
    }

    &__subtitle {
      font-size: 1.1rem;
      margin: 0;
    }

    &__toggle {
      color: $brand-sea-green;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 30px;
      height: 30px;
      background-color: $brand-yellow;
      border-radius: 50%;
      z-index: 10;
      transition: 0.3s ease background-color, 0.3s ease transform;
      transform: rotate(0);

      &:hover {
        background-color: darken($brand-yellow, 10);
      }

      svg {
        flex-shrink: 0;
      }
    }
  }

  .editor-module-content {
    //border-left: 1px solid $brand-sea-green;
    //border-right: 1px solid $brand-sea-green;
    //border-bottom: 1px solid $brand-sea-green;
    border-radius: 5px;
    margin-top: -5px;
    overflow: hidden;
    background-color: #EFEFEF;

    &__wrapper {
      padding: calc(1rem + 5px) 1rem 1rem 1rem;
    }

    input {
      position: relative;
      border: 1px solid $brand-gray-md;
      border-radius: 4px;
      background-color: white;
      line-height: 1.75;
      overflow-x: hidden;
      text-overflow: ellipsis;
      min-height: 1.75em;
      user-select: none;
      color: inherit;
      width: 100%;
      outline: none;

      @include media($screen-sm, max) {
        padding: 0.5rem 1rem;
        width: 100%;
      }

      &.flatpickr-input {

        @include media($screen-sm, max) {
          padding: 0;
          max-width: 200px;
        }
      }
    }

    &__controls {
      margin-top: 1rem;

      button {
        @include button;
        display: flex;
        align-items: center;
        color: black;
        font-size: 1.2rem;
        background-color: $brand-yellow;
        padding: 0.5rem 2rem;
        transition: 0.3s ease background-color;
        margin-left: auto;

        svg {
          color: $brand-sea-green;
          margin-right: 10px;
        }

        &:hover {
          background-color: darken($brand-yellow, 10);
        }
      }
    }
  }
}
