&.guide {
  background-color: white;

  .share-panel {

    &__wrapper {
      max-width: 900px;
    }
  }
}
