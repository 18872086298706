.header {
  font-family: 'Nunito', Helvetica, Arial, sans-serif;
  display: flex;
  justify-content: space-between;
  padding: 0;
  background-color: white;
  height: 60px;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 50;

  @include media($nav-breakpoint, max) {
    display: none;
  }

  &__brand {
    display: flex;
    align-items: center;
    padding-left: 1rem;
    background-color: white;
  }

  &__logo-wrapper {
    width: 180px;
  }

  &__logo {
    width: 100%;
    height: 100%;
  }

  &__link {
    color: white;
    text-decoration: none;
  }

  &__menu {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    background-color: white;
  }

  &__widgets {
    display: flex;
    align-items: center;
    padding-right: 1rem;

    .search-widget {

      &__input {

        @include media(1410px, max) {
          max-width: 200px;
          min-width: 100%;
        }
      }
    }
  }

  .navigation {
    display: flex;
    align-items: center;
    list-style: none;
    margin: 0 0 0 1rem;
    padding: 0;

    &__item {
      flex-shrink: 0;

      &:not(:first-child) {
        min-width: 100px;

        @include media(1510px, max) {
          max-width: 100%;
          min-width: auto;
        }
      }

      &:hover {

        .dropdown {
          margin-top: 0;
          transition: 0.3s ease margin-top;
          //transition-delay: 0s;
        }

        .navigation__link {
          color: white;
          text-decoration: none;
          background-color: $brand-sea-green;
          transition: 0.3s ease color, 0.3s ease background-color;

          &_has-icon {

            svg {

              &.md-icon-plus {
                color: black;
                background-color: white;
              }
            }
          }
        }
      }
    }

    &__link {
      color: black;
      font-size: 1.8rem;
      font-weight: 600;
      display: inline-block;
      padding: 8px 2.8rem;
      text-decoration: none;
      transition: 0.3s ease color, 0.3s ease background-color, 0.3s ease border;
      position: relative;
      width: 100%;
      text-align: center;
      height: 60px;

      &:hover {
        color: $brand-sea-green;
        text-decoration: none;
        background-color: $brand-yellow;
      }

      &:not(&_has-icon) {
        font-weight: 600;
      }

      &_has-icon {
        display: flex;
        align-items: center;
        padding: 0.61rem;
        height: 60px;
        width: 60px;
        overflow: hidden;

        &:hover {
          border-bottom: 1px solid transparent;
        }

        svg {
          width: 40px;
          height: 40px;

          &.md-icon-plus {
            color: white;
            background-color: black;
            border-radius: 50%;
          }
        }
      }

      &.active {
        color: white;
        background-color: black;
        border-bottom: 1px solid black;
      }

      .ember-initials {
        border-radius: 50%;
      }
    }

    &__search {
      height: 40px;
      margin: 0 1rem;
      padding: 0.5rem 1rem;
      outline: none;
      border: 1px solid $brand-yellow;
      min-width: 250px;
    }

    .dropdown {
      display: flex;
      position: absolute;
      z-index: -5;
      left: 0;
      width: 100%;
      transition: 0.3s ease margin-top;
      //transition-delay: 5s;
      padding: 0;
      margin-top: -100%;
      background-color: $brand-sea-green-secondary;
      //padding-left: 60px;
      padding-left: 1rem;
      list-style: none;
      border-top: 1px solid #ffffff9e;

      &_gray {
        background-color: #e6e6e6;

        .dropdown__item {

          .dropdown-item-title {
            color: $brand-sea-green;
          }

          .dropdown__link {
            color: black;

            &.active {
              border-color: black;
            }
          }
        }
      }

      &_main {
        background-color: $brand-gray-lt;
        border-top: 5px solid black;

        .dropdown__item {
          display: block;
          min-width: 150px;
          max-width: 350px;
          margin: 1rem 0;
          border-left: 1px solid $brand-gray;
        }

        .dropdown__link {
          color: black;
          padding: 0 0.8rem;
          border-bottom: none;

          &:hover, &.active {
            border-bottom: none;
          }
        }
      }

      &__item {
        display: flex;
        align-items: center;

        .dropdown-item-title {
          color: $brand-yellow;
          min-width: 300px;
          display: block;
          font-weight: 700;
          font-size: 1.1rem;
        }
      }

      &__item-description {
        font-size: 0.8rem;
        margin: 0;
        padding: 0 1rem;
        font-weight: 300;
      }

      &__link {
        color: white;
        font-size: 1.2rem;
        white-space: nowrap;
        padding: 8px 1rem 4px 1rem;
        border-bottom: 4px solid transparent;
        display: inline-block;
        text-decoration: none;

        &:hover, &.active {
          border-bottom: 4px solid $brand-yellow;
        }

        img {
          width: 20px;
          height: 20px;
          margin-right: 10px;
        }
      }
    }
  }
}

.header-mobile {
  grid-area: header;
  min-height: 60px;
  background-color: white;
  position: fixed;
  z-index: 50;
  width: 100%;

  @include media($nav-breakpoint, min) {
    display: none;
  }

  .header-mobile-bar {
    margin: 0;
    padding: 0;
    list-style: none;
    background-color: white;
    position: relative;

    display: flex;
    justify-content: flex-end;

    .header-mobile-bar-item {
      height: 60px;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      background-color: $brand-sea-green;
      display: flex;
      align-items: center;

      &:hover {

        .header-mobile-bar-dropdown {
          top: 60px;
        }
      }

      .current-item {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0;
        position: relative;
        z-index: 50;
        height: 60px;
        width: 100%;
        background-color: white;
      }

      .header-mobile-bar-dropdown {
        margin: 0;
        padding: 0;
        border-top: none;
        background-color: $brand-gray-lt;
        transition: 0.3s ease top;
        position: absolute;
        z-index: 10;
        width: 100%;
        top: -200%;

        .header-mobile-bar-dropdown-item {
          display: flex;
          align-items: center;
          justify-content: space-between;
          padding: 0 0.5rem;
          border-top: 1px solid white;

          &__title {
            color: black;
            display: block;
            font-weight: 700;
            font-size: 1rem;
            order: 1;
          }

          &__link {
            display: flex;
            align-items: center;
            color: black;
            font-size: 2rem;
            font-weight: 800;
            white-space: nowrap;
            text-decoration: none;
            line-height: 2rem;
            order: 0;

            //&::before {
            //  display: block;
            //  content: "";
            //  width: 24px;
            //  height: 24px;
            //  background-color: black;
            //  border-radius: 50%;
            //  margin-right: 0.5rem;
            //  opacity: 0;
            //  transition: 0.3s ease opacity;
            //}
            //
            //&:hover {
            //
            //  &::before {
            //    opacity: 1;
            //  }
            //}
          }
        }
      }
    }
  }

  &__brand {
    display: flex;
    align-items: center;
    padding-left: 0.5rem;
    flex-grow: 1;
  }

  &__logo-wrapper {
    width: 180px;
  }

  &__logo {
    width: 100%;
    height: 100%;
  }

  &__title {
    font-family: 'Nunito', sans-serif;
    color: white;
    font-size: 1.66rem;
    text-decoration: none;
    text-transform: uppercase;
    display: block;
    white-space: nowrap;
    line-height: 31.5px;
    font-weight: bold;
    margin: 0 0 0 0.15rem;
    transform: translateY(3px);

    span {
      color: $brand-yellow;
    }
  }

  .header-mobile-toggle {
    grid-area: toggle;
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 60px;
    height: 60px;
    padding: 5px;
    transition: 0.3s ease background-color;
    position: relative;
    z-index: 60;

    svg {
      width: 40px;
      height: 40px;

      &.md-icon-plus {
        color: white;
        background-color: black;
        border-radius: 50%;
        transition: 0.3s ease color, 0.3s ease background-color;
      }
    }

    &:hover, &_active {
      background-color: black;

      svg {

        &.md-icon-plus {
          color: black;
          background-color: white;
        }
      }
    }

    &_active {

      .md-icon-plus {
        transform: rotate(45deg);
      }
    }

    .md-icon-plus {
      transition: 0.3s ease all;
      flex-shrink: 0;
      pointer-events: none;
    }
  }

  .header-mobile-widgets {
    grid-area: widgets;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    background-color: white;
    position: relative;
    z-index: 60;

    .user-widget {

      &__button {
        width: 60px;
        height: 60px;
        padding: 0;
        background: none;
        margin: 0;
        justify-content: center;
        border-radius: 0;

        span {
          display: none;
        }
      }
    }
  }

  .header-mobile-menu {
    color: $brand-yellow;

    position: fixed;
    top: 60px;
    z-index: 20;
    height: calc(100vh - 60px);
    overflow: scroll;

    width: 360px;
    right: -360px;
    margin: 0;
    padding: 1rem 1rem 10rem 1rem;
    list-style: none;
    background-color: $brand-sea-green-secondary;
    border-top: 1px solid $brand-yellow;

    &__item {
      font-size: 1.2rem;
      font-weight: 700;
      color: $brand-yellow;
      margin-bottom: 10px;

      &_separator {
        height: 2px;
        background-color: $brand-yellow;
      }

      .search-widget {
        margin-right: 0;

        &__input {
          min-width: initial;
        }
      }
    }

    &__link {
      color: $brand-yellow;
      text-decoration: none;
    }

    &__dropdown {
      list-style: none;
      margin: 10px 0 0 1rem;
      padding: 0;
    }

    &__dropdown-title {
      grid-column-start: 1;
      grid-column-end: 3;
      color: white;
      margin: 0;
      font-weight: 600;
    }

    &__dropdown-item {
      font-weight: 500;
      margin-bottom: 10px;
      display: flex;
      flex-direction: column;

      &_separator {
        height: 2px;
        background-color: $brand-yellow;
      }

      &_title {
        color: white;
        font-weight: 600;
      }

      &_stack {
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-gap: 0.5rem;
      }
    }

    &__dropdown-link-description {
      margin: 0;
      font-size: 0.9rem;
      color: white;
      line-height: 1.1;
    }

    &__dropdown-link {
      color: $brand-yellow;
      display: flex;
      align-items: center;
      text-decoration: none;
    }
  }
}

