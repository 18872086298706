.breadcrumbs {
  display: flex;
  flex-wrap: wrap;
  margin: 0;
  padding: 0;
  list-style: none;

  &__item {
    white-space: nowrap;
    font-weight: 600;
    color: white;

    &:not(:last-child) {

      &::after {
        content: ">";
        margin: 0 5px;
      }
    }

    &:last-child {

      .breadcrumbs__link {
        text-decoration: none;
      }
    }
  }

  &__link {
    color: white;
  }
}
