.wanderilo-editor {
  padding: 1rem 0;
  overflow: hidden;
  position: relative;
  z-index: 10;

  &__wrapper {
    @include wrapper(1100px);
  }

  &__top-bar {
    display: grid;
    grid-template-columns: 1fr 1fr;
    margin: 0 0 1rem 0;
  }

  &__title {
    color: $brand-sea-green;
    display: inline-block;
    margin: 0;
    align-self: center;
    line-height: 1;

    @include media($screen-sm, max) {
      font-size: 1.8rem;
    }
  }

  &__cover-image-uploader {
    margin-top: 1rem;
  }

  &__map-image-uploader {
    margin-top: 1rem;
  }

  &__wanderilo-title {
    margin-bottom: 1rem;
  }

  &__module {

    &:not(:last-child) {
      margin-bottom: 0.5rem;
    }
  }

  &__controls {

    button {
      @include button;
      display: flex;
      align-items: center;
      color: black;
      font-size: 1.2rem;
      background-color: $brand-yellow;
      padding: 0.5rem 2rem;
      transition: 0.3s ease background-color;
      margin-left: auto;

      @include media($screen-sm, max) {
        font-size: 1rem;
        padding: 0.5rem 1rem;
      }

      svg {
        color: $brand-sea-green;
        margin-right: 10px;
      }

      &:hover {
        background-color: darken($brand-yellow, 10);
      }
    }
  }

  .user-picker-module {

    .user-picker-user-profile {
      margin-top: 1rem;

      &__content {
        color: white;
        display: grid;
        grid-template-columns: 66px 1fr;
        grid-template-areas: "avatar title";
        grid-gap: 1rem;
        padding: 0.5rem;
        background-color: $brand-sea-green;
        border-radius: 0.5rem;
      }

      &__avatar {
        border-radius: 50%;
        object-fit: cover;
        grid-area: avatar;
        border: 3px solid $brand-yellow;

        @include media($screen-sm, max) {
          margin-right: 0;
        }
      }

      &__title-wrapper {
        position: relative;
        grid-area: title;
      }

      &__username {
        margin: 0;
        line-height: 1;
        font-size: 1.5rem;
      }

      &__title {
        margin: 0;
        line-height: 1;
        font-size: 1rem;
      }

      &__tagline {
        margin: 0;
        font-size: 0.8rem;
        line-height: 0.9rem;
      }
    }
  }

  .selected-entries-list {
    margin: 1rem 0 0 0;
    padding: 0;
    list-style: none;

    .selected-entries-list-entry {
      color: white;
      padding: 0.5rem;
      background-color: $brand-sea-green;
      border-radius: 0.5rem;
      display: flex;

      &:not(:last-child) {
        margin-bottom: 10px;
      }

      &__reorder-button {
        margin-right: 1rem;
        cursor: pointer;

        @include media($screen-md, max) {
          margin-right: 0.5rem;
        }

        svg {
          width: 18px;
          height: 40px;
        }
      }
    }
  }
}
