.lifeline-item-place {
  display: grid;
  grid-template-areas: "label wrapper"
  "panels panels";
  grid-template-columns: min-content 1fr;
  position: relative;
  z-index: 10;
  margin: 1rem;

  @include media($screen-sm, max) {
    grid-template-areas: "label wrapper"
                         "mobile-controls mobile-controls"
                         "panels panels";
  }

  &__label {
    grid-area: label;
    display: flex;
    align-items: center;
    justify-content: center;
    color: $brand-sea-green;
    background-color: $brand-yellow;
    writing-mode: vertical-rl;
    text-orientation: mixed;
    transform: rotate(180deg);
    padding: 0.2rem;
    height: 100%;
    border-top: 1px solid lighten($brand-gray-md, 10);
    border-right: 1px solid lighten($brand-gray-md, 10);
    border-bottom: 1px solid lighten($brand-gray-md, 10);
    border-top-right-radius: 25px;
    border-bottom-right-radius: 25px;
  }

  &__wrapper {
    color: $brand-sea-green;
    display: grid;
    grid-area: wrapper;
    grid-template-areas: "image info controls";
    grid-template-columns: 100px 1fr 100px;
    grid-gap: 0.5rem;
    background-color: white;
    padding: 0.5rem;
    border: 1px solid lighten($brand-gray-md, 10);
    border-top-right-radius: 25px;
    border-bottom-right-radius: 25px;

    @include media($screen-sm, max) {
      grid-template-areas: "image info";
      grid-template-columns: 100px 1fr;
      padding: 0;
    }
  }

  &__user-info {

  }

  &__image {
    grid-area: image;
    width: 100px;
    height: 100%;
    object-fit: cover;
  }

  &__content {
    display: flex;
    flex-direction: column;
    grid-area: content;
  }

  &__controls {
    grid-area: controls;
    display: flex;
    flex-direction: column;

    @include media($screen-md, max) {
      display: none;
    }

    a, button {
      display: flex;
      color: $brand-sea-green;
      font-weight: 500;
      background-color: $brand-gray-lt;
      text-decoration: none;
      padding: 0.2rem 0.5rem;
      text-align: center;
      transition: 0.3s ease background-color;

      &:hover {
        background-color: darken($brand-gray-lt, 10);
      }

      .md-icon {
        margin-right: 0.5rem;
      }

      &:not(:last-child) {
        margin-bottom: 5px;
      }
    }
  }

  &__mobile-controls {
    grid-area: mobile-controls;
    display: flex;
    justify-content: center;

    @include media($screen-md, min) {
      display: none;
    }
  }

  &__mobile-control {
    display: flex;
    align-items: center;
    font-size: 0.7rem;
    font-weight: 600;
    padding: 2px 8px;
    border-left: 1px solid lighten($brand-gray-md, 10);
    border-right: 1px solid lighten($brand-gray-md, 10);
    border-bottom: 1px solid lighten($brand-gray-md, 10);
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    overflow: hidden;
    height: 25px;

    &:not(:last-child) {
      margin-right: 3px;
    }

    &_primary {
      display: flex;
      color: $brand-sea-green;
      font-weight: 500;
      text-decoration: none;
      text-align: center;
      transition: 0.3s ease background-color;

      &:hover {
        background-color: transparent;
        color: darken($brand-sea-green, 10);
      }
    }

    &_danger {
      display: flex;
      justify-content: center;
      align-items: center;
      min-width: auto;
      background-color: transparent;
      color: $brand-red;

      .md-icon {
        margin: 0;
      }

      &:hover {
        background-color: transparent;
        color: darken($brand-red, 10);
      }
    }

    svg {
      flex-shrink: 0;
      flex-grow: 1;
    }
  }

  &__card {
    grid-area: info;
  }

  .lifeline-item-place-card {
    display: flex;

    &__user-avatar-wrapper {
      display: flex;
      align-items: center;
      margin-left: calc(-30px - 0.5rem);
    }

    &__info {
      margin-left: 0.5rem;
    }

    &__timestamp-wrapper {
      margin-top: -0.5rem;

      @include media($screen-md, max) {
        margin-top: 0;
        margin-left: -90px;
      }
    }

    &__timestamp {
      font-size: 0.8rem;
      display: table-cell;
      padding: 0 10px 3px 10px;
      background-color: $brand-yellow;
      border-bottom-left-radius: 10px;
      border-bottom-right-radius: 10px;
      white-space: nowrap;

      @include media($screen-md, max) {
        font-size: 0.6rem;
      }
    }

    &__category-wrapper {
      grid-area: category-wrapper;
    }

    &__category {
      display: inline-block;
      color: $brand-yellow;
      background-color: #5c5c5c;
      font-weight: 600;
      font-size: 0.7rem;
      padding: 0.2rem 0.5rem;
    }

    &__header {
      margin: 0;
      line-height: 1;

      @include media($screen-sm, max) {
        font-size: 0.9rem;
      }
    }
  }

  .map-box {

    .leaflet-container {
      width: 100%;
      height: 100%;
    }

    .leaflet-control-container {
      display: none;
    }
  }
}
