.place-manager {
  position: relative;
  z-index: 10;
  display: flex;
  //height: calc(100vh - 114px);
  //overflow: hidden;

  &__list {
    width: 100%;
    max-width: $wrapper-max-width;
    margin: 0 auto;
    padding: 2rem 1rem;
    //overflow-y: scroll;

    @include media($screen-md, max) {
      padding: 2rem 0;
    }
  }

  &__sidebar {
    position: absolute;
    width: 460px;
    height: 100%;
    background-color: $brand-gray-lt;
    top: 0;
    right: 0;
    z-index: 20;
    border-left: 1rem solid $brand-sea-green;
    transform: translateX(calc(460px + 1rem + 32px));
    transition: 0.3s ease transform;

    @include media($screen-sm, max) {
      width: 330px;
      transform: translateX(calc(330px + 1rem + 32px));
    }

    &_active {
      z-index: 30;
      transform: translateX(0);

      @include media($screen-sm, max) {

      }
    }
  }

  .pm-sidebar {

    &_wander {
      .sidebar-tab__label {
        top: 1rem;
      }
    }

    &_guide {

      .sidebar-tab__label {
        top: 180px;
      }
    }

    &__tab {
      display: flex;
      flex-direction: column;
      width: 100%;
      height: 100%;
    }

    .sidebar-tab {

      .wander-title {
        position: relative;
        border: 1px solid $brand-gray-md;
        border-radius: 4px;
        background-color: $brand-gray-lt;
        line-height: 1.75;
        overflow-x: hidden;
        text-overflow: ellipsis;
        min-height: 1.75em;
        user-select: none;
        color: inherit;
        width: 100%;
        outline: none;
        padding: 0.5rem;
        font-size: 1.2rem;

        @include media($screen-sm, max) {
          padding: 0.5rem 1rem;
          width: 100%;
        }
      }

      &__title {
        font-size: 1.25rem;
        display: flex;
        color: $brand-sea-green;
        margin: 0;
        padding: 1rem;
        border-bottom: 1px solid $brand-gray-md;
      }

      &__list {
        padding: 0.5rem;
        flex-grow: 1;

        ol {
          overflow: scroll;
          height: calc(100vh - 360px);
          margin-top: 10px;
        }
      }

      &__controls {
        display: flex;
        justify-content: space-around;
        align-items: center;
        transform: translateY(60px);
        opacity: 0;
        transition: 0.3s ease transform, 0.3s ease opacity;
        min-height: 80px;
        border-top: 1px solid $brand-gray-md;

        &_active {
          transform: translateY(0);
          opacity: 1;
        }

        button {
          color: $brand-yellow;
          font-weight: 600;
          font-size: 1.1rem;
          padding: 0.5rem 1rem;
          border: none;
          background-color: $brand-sea-green;
          cursor: pointer;
          transition: 0.3s ease background-color;

          &:hover {
            background-color: darken($brand-sea-green, 10);
          }

          &:disabled {
            background-color: $brand-gray-md;
          }
        }
      }

      &__label {
        position: absolute;
        display: flex;
        align-items: center;
        justify-content: center;
        left: calc(-32px - 1rem);
        background-color: $brand-sea-green;
        color: $brand-yellow;
        font-size: 1.2rem;
        font-weight: 500;
        border-top-left-radius: 10px;
        border-bottom-left-radius: 10px;
        top: 2rem;
      }

      .tab-label {

        &__button {
          display: flex;
          align-items: center;
          font-size: 1.2rem;
          font-weight: 500;
          padding: 0.5rem 0 0.5rem 0.5rem;
          color: $brand-yellow;
          border: none;
          background: none;
          cursor: pointer;
        }
      }
    }
  }
}
