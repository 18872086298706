.wanderwall-list {
  margin: 0 auto;
  padding: 1rem 0;
  width: 100%;
  max-width: $wrapper-max-width;

  &__list {
    margin: 0;
    padding: 0;
  }

  &__placeholder {
    text-align: center;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%)
  }

  &__title {
    font-size: 2rem;
    color: $brand-sea-green;
    margin: 0;
  }

  &__cta {
    display: inline-block;
    font-size: 1.4rem;
    color: $brand-sea-green;
    background-color: $brand-yellow;
    border: 3px solid $brand-sea-green-lt;
    border-radius: 2rem;
    text-decoration: none;
    padding: 0.5rem 2rem;
    margin-top: 2rem;
  }
}
