@import "private/places";

.private {
  @import "private/spot";
  @import "private/wai";
  @import "private/wanderwall";
  @import "private/wandervwerse";

  display: grid;
  grid-template-areas: "header" "main" "footer";
  grid-template-rows: 60px 1fr 160px;
  min-height: 100vh;

  .header {
    grid-area: header;
  }

  .breadcrumbs-wrapper {
    background-color: black;
    padding: 0.3rem;
    position: relative;
    z-index: 20;

    .breadcrumbs {
      max-width: $wrapper-max-width;
      margin: 0 auto;
      font-size: 13px;
      font-weight: 500;
    }
  }

  .main {
    grid-area: main;

    @include media($screen-sm, max) {
      overflow-x: hidden;
    }
  }

  .footer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    grid-area: footer;
    background-color: white;
    border-top: 1px solid black;

    &__title {
      color: $brand-yellow;
      font-size: 2rem;
      text-transform: uppercase;
      margin: 0;
    }

    &__copy {
      color: black;
      margin: 0;
    }

    &__logo {
      width: 180px;
    }
  }
}
