.file-uploader {

  &__files {
    display: grid;
    grid-template-columns: repeat(auto-fill, 120px);
    grid-gap: 1rem;
    margin: 0 0 1rem 0;

    .file {
      position: relative;

      &__preview {
        border: 1px solid $brand-sea-green-lt;
      }

      &__delete {
        border: none;
        padding: 0;
        background: none;
        position: absolute;
        top: 5px;
        right: 5px;

        svg {
          color: $brand-red;
        }

        &:hover {

          svg {
            color: darken($brand-red, 10);
          }
        }
      }
    }
  }

  &__filepicker {

    label {
      color: $brand-sea-green;
      width: 100%;
      font-size: 1.4rem;
      font-weight: 600;
      text-align: center;
      cursor: pointer;
      display: block;
      border: 2px dashed $brand-sea-green;
      padding: 0.5rem;
      transition: 0.3s ease background-color;

      &:hover {
        background-color: darken($brand-gray-lt, 10);
      }

      input {
        display: none;
      }
    }
  }
}
