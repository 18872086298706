.wanderwall-user-list {
  display: grid;
  grid-template-columns: 60px 1fr;
  list-style: none;
  margin: 0 auto;
  padding: 1rem;
  width: 100%;
  max-width: $wrapper-max-width;

  &__all {
    display: flex;
    align-items: center;
    position: relative;
    z-index: 2;

    a {
      color: $brand-sea-green;
      font-size: 1.6rem;
      font-weight: bold;
      border-right: 1px solid $brand-gray-md;
      padding: 0.5rem 0.84rem;
      text-decoration: none;
    }
  }

  &__item {
    width: 68px;

    &:not(:last-child) {
      margin-right: 1rem;
    }
  }

  &__link {
    display: block;
    border: 4px solid white;
    border-radius: 50%;

    &_active {
      border: 4px solid $brand-yellow;
    }
  }

  &__avatar {
    border-radius: 50%;
    object-fit: cover;
    vertical-align: middle;
  }

  &__username {
    color: $brand-sea-green;
    font-size: 0.7rem;
    font-weight: 600;
    margin-top: 0;
    text-align: center;
  }

  &__swiper {
    overflow: hidden;
    margin: 0 1rem;
  }

  &__swiper-wrapper {
    margin: 0;
    padding: 0;
    list-style: none;
  }
}
