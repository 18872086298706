.user-avatar {

  &__link {
    display: block;
    position: relative;
    z-index: 20;
  }

  &__image {
    border-radius: 50%;
  }
}
