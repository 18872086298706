.dashboard-panel-toggle {
  color: $brand-yellow;
  background-color: $brand-sea-green;
  border: none;
  transition: 0.3s ease background-color;

  &:hover {
    background-color: darken($brand-sea-green, 10);
  }
}

.user-profile-manager {

  &__wrapper {
    padding: 1rem;
  }

  &__title {
    color: $brand-sea-green;
    text-align: center;
    margin-top: 0;
  }

  &__avatar-wrapper {
    display: flex;
    justify-content: center;
    margin: 1rem 0;
  }

  &__avatar-box {
    position: relative;
  }

  &__avatar {
    border-radius: 50%;
  }

  &__controls {
    border-bottom: 1px solid $brand-sea-green;
    padding-bottom: 1rem;
    margin-bottom: 1rem;
  }

  &__control {
    display: flex;
    align-items: center;
    color: $brand-yellow;
    background-color: $brand-sea-green;
    padding: 0.2rem 1rem;
    transition: 0.3s ease background-color;

    svg {
      margin-right: 5px;
    }

    &:hover {
      background-color: darken($brand-sea-green, 10);
    }
  }

  .user-profile-manager-avatar-uploader {
    position: absolute;
    right: 0;
    bottom: 0.5rem;

    &__label {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 32px;
      height: 32px;
      border-radius: 50%;
      color: $brand-yellow;
      background-color: $brand-sea-green;
      cursor: pointer;
      transition: 0.3s ease background-color;

      &:hover {
        background-color: darken($brand-sea-green, 10);
      }
    }

    &__input {
      display: none;
    }
  }

  .user-profile-manager-info {
    display: grid;
    grid-template-areas: "first-name last-name"
                         "tagline tagline";
    grid-template-columns: 1fr 1fr;
    grid-gap: 0.5rem;
    margin-bottom: 1rem;

    &__first-name {
      grid-area: first-name;
      outline: none;
    }

    &__last-name {
      grid-area: last-name;
      outline: none;
    }

    &__tagline {
      grid-area: tagline;
      width: 100%;
      outline: none;

      &::placeholder {
        font-size: 1rem;
      }
    }

    &__first-name, &__last-name, &__tagline {
      font-size: 1rem;
      border: 1px solid $brand-sea-green;
      border-radius: 4px;
      padding: 0.2rem 0.5rem;
      width: 100%;
    }
  }

  .referral-box {
    margin-bottom: 0.5rem;

    label {
      font-size: 13px;
      text-align: center;
      display: block;
      margin-bottom: 5px;
    }

    textarea {
      font-size: 0.8rem;
      border: 1px solid $brand-sea-green;
      border-radius: 4px;
      padding: 0.2rem 0.5rem;
      width: 100%;
      outline: none;
    }
  }

  &__category-wrapper {
    margin-bottom: 0.5rem;

    .ember-power-select-trigger {
      border: 1px solid $brand-sea-green;
    }
  }

  &__tags-wrapper {
    margin-bottom: 0.5rem;

    .emberTagInput {
      border: 1px solid $brand-sea-green;
      border-radius: 5px;
    }
  }
}
