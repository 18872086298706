.wanderilo-homepage-view {
  background-color: white;
  padding-bottom: 2rem;

  &__title {
    color: green;
    background-color: $brand-yellow;
    text-align: center;
    margin: 0;
    padding: 0;
    font-weight: 500;

    span {
      font-weight: 700;
    }
  }

  &__list-wrapper {
    background-color: $brand-sea-green;
    display: flex;
    justify-content: center;
  }

  &__list {
    max-width: 100%;
    margin: 0 auto;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(345px, 1fr));
    grid-gap: 2rem;
    padding: 3rem 0.5rem;
    position: relative;
    //overflow: hidden;

    //@include media($screen-md, min) {
    //  max-width: calc($wrapper-max-width + 120px);
    //  margin: 0 60px;
    //
    //  .swiper-button-prev {
    //    transform: translateX(-50px);
    //  }
    //
    //  .swiper-button-next {
    //    transform: translateX(50px);
    //  }
    //}

    .swiper-button-prev, .swiper-button-next {
      color: $brand-yellow;
      opacity: 0.8;
      position: absolute;
      top: 183px;
    }
  }

  .whv-entry {
    position: relative;
    border-radius: 1rem;
    background-color: white;
    cursor: pointer;
    box-shadow: 3px 3px 3px 0 rgba(51, 51, 51, 0.4);

    &_selected {

      &::after {
        content: "";
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 40px 27px 0 27px;
        border-color: $brand-sea-green transparent transparent transparent;
        transform: translateX(-50%);
        left: 50%;
        bottom: calc(-3rem - 40px);
        position: absolute;
      }
    }

    &__image {
      display: block;
      border-top-left-radius: 1rem;
      border-top-right-radius: 1rem;
      width: 100%;
      height: 250px;
      object-fit: cover;
    }

    &__label {
      text-transform: lowercase;
      color: $brand-sea-green;
      background-color: $brand-yellow;
      position: absolute;
      top: 0;
      left: 50%;
      transform: translateX(-50%);
      font-weight: 600;
      font-size: 1.1rem;
      padding: 0.3rem 1.4rem;
      border-bottom-left-radius: 10px;
      border-bottom-right-radius: 10px;
    }

    &__user-avatar-link {
      position: fixed;
      top: 0;
      left: 0;
    }

    &__user-avatar {
      position: absolute;
      top: 20px;
      left: 20px;
    }

    &__user-avatar {
      border-radius: 50%;
    }

    .whv-entry-counter {
      color: $brand-sea-green;
      background-color: $brand-yellow;
      font-weight: 600;
      display: flex;
      justify-content: space-around;
      padding: 0.5rem;
      border-bottom: 1px solid lighten($brand-gray-md, 10);

      &__field {
        display: flex;
        align-items: center;
      }

      &__number {
        color: $brand-yellow;
        background-color: $brand-sea-green;
        border-radius: 50%;
        width: 30px;
        height: 30px;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-right: 0.15rem;
      }
    }

    &__title {
      font-size: 1.5rem;
      text-align: center;
      color: $brand-sea-green;
      text-transform: uppercase;
      font-weight: 600;
      margin: 0.5rem 0.5rem 0 0.5rem;
      padding: 0 0 0.5rem 0;
      border-bottom: 1px solid darken($brand-gray-lt, 10);
    }

    &__tags {
      padding: 1rem;
      width: calc(100% - 2rem);
      overflow: hidden;
      margin: 0 1rem;

      .tags-list {
        justify-content: center;
        white-space: nowrap;
        animation: scrollText 20s linear 3s infinite;
        overflow: visible;

        &__tag {
          font-size: 1rem;
          //font-weight: 600;
        }

        @include media($screen-sm, max) {
          max-width: 100%;
        }
      }
    }

    &__controls {
      display: grid;
      grid-template-columns: 1fr 1fr;
      padding: 0;
      border-top: 1px solid darken($brand-gray-lt, 10);
    }

    &__control {
      color: $brand-sea-green;
      text-transform: uppercase;
      font-size: 1.1rem;
      font-weight: 600;
      display: flex;
      justify-content: center;
      align-items: center;
      text-decoration: none;
      padding: 0.5rem;
      transition: 0.3s ease background-color;

      &:not(:last-child) {
        border-right: 1px solid darken($brand-gray-lt, 10);
      }

      &:first-child {
        border-bottom-left-radius: 1rem;
      }

      &:last-child {
        border-bottom-right-radius: 1rem;
      }

      &:hover {
        background-color: darken($brand-gray-lt, 10);
      }
    }
  }

  &__content-wrapper {
    padding: 4rem 0;
    background-color: white;
  }

  &__image-wrapper {
    margin-bottom: 2rem;
  }

  &__image {
    width: 100%;
    min-height: 600px;
  }

  &__content {
    @include wrapper();
  }

  .whv-selected-entry {
    position: relative;

    .whv-selected-entry-header {
      display: grid;
      grid-template-areas: "avatar body";
      grid-template-columns: 140px 1fr;
      grid-column-gap: 2rem;
      margin-bottom: 2rem;

      @include media($screen-sm, max) {
        grid-template-areas: "avatar"
                             "body";
        grid-template-columns: 1fr;
      }

      &__avatar {
        grid-area: avatar;
        align-self: center;

        @include media($screen-sm, max) {
          justify-self: center;
        }

        .user-avatar {

          &__username {
            color: $brand-sea-green;
            text-align: center;
            font-weight: 600;
            margin-bottom: 0;
            margin-top: 4px;
          }
        }
      }

      &__body {
        grid-area: body;
      }

      &__title {
        color: $brand-sea-green;
        font-size: 3rem;
        text-transform: uppercase;
        font-weight: 800;
        text-align: center;
        margin: 0;
      }

      &__description {

      }

      &__tags {
        display: flex;
        justify-content: center;

        .tags-list {
          flex-wrap: wrap;
          justify-content: center;

          @include media($screen-sm, max) {
            max-width: 100%;
          }

          &__tag {
            font-size: 1rem;
            padding: 0.4rem 1rem;
            margin-bottom: 5px;
          }
        }
      }
    }

    .whv-selected-entry-section {

      &:not(:last-of-type) {
        margin-bottom: 4rem;
      }

      &__counter {
        color: $brand-yellow;
        background-color: $brand-sea-green;
        border-radius: 50%;
        width: 40px;
        height: 40px;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-right: 0.5rem;
      }

      &__title {
        font-size: 2rem;
        display: flex;
        align-items: center;
        color: $brand-sea-green;
      }
    }

    .whv-selected-entry-guides {
      display: grid;
      grid-template-columns: repeat(auto-fill, minmax(320px, 1fr));
      grid-gap: 2rem;

      @include media($screen-sm, max) {

        .wanderilo-guide-item {

          &:not(:first-of-type) {
            display: none;
          }
        }
      }
    }

    .whv-selected-entry-wanders {
      display: grid;
      grid-template-columns: repeat(auto-fill, minmax(320px, 1fr));
      grid-gap: 2rem;

      @include media($screen-sm, max) {

        .wanderilo-wander-item {

          &:not(:first-of-type) {
            display: none;
          }
        }
      }
    }

    .whv-selected-entry-places {
      display: grid;
      grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
      grid-gap: 2rem;

      @include media($screen-sm, max) {

        .wanderilo-place-item {

          &:not(:first-of-type) {
            display: none;
          }
        }
      }
    }

    &__controls {
      @include wrapper();
      display: flex;
      justify-content: flex-end;
    }

    &__control {
      color: $brand-yellow;
      background-color: $brand-sea-green;
      padding: 0.7rem 2.2rem;
      font-size: 1.2rem;
      font-weight: 600;
      transition: 0.3s ease background-color;
      text-decoration: none;
      z-index: 10;
      display: block;

      &:hover {
        background-color: darken($brand-sea-green, 10);
      }
    }
  }
}
