.content-box {

  &__wrapper {
    @include wrapper();
    display: flex;
    justify-content: space-between;
    align-items: baseline;
    margin: 2rem auto 0 auto;
  }

  &__header {
    display: flex;
    align-items: center;
    color: $brand-sea-green;
    font-family: 'Nunito', sans-serif;
    font-size: 3rem;
    margin: 0;

    span {
      color: $brand-yellow;
      font-size: 2.8rem;
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: $brand-sea-green;
      border-radius: 50%;
      width: 3.4rem;
      height: 3.4rem;
      margin-right: 1rem;
    }
  }

  &__controls {

    a {
      color: $brand-sea-green;
      font-size: 1.5rem;
      font-weight: 600;
      text-decoration: none;
    }
  }
}
