.wander-manager {
  position: relative;
  z-index: 10;
  display: flex;
  flex-direction: column;
  overflow-y: hidden;
  margin-top: 3rem;

  &__breadcrumbs {
    @include wrapper();
    margin-top: 1rem;
  }

  &__title {
    @include wrapper();
    color: $brand-sea-green;
    margin: 0 auto;
  }

  &__list {
    width: 100%;
    max-width: $wrapper-max-width;
    margin: 0 auto;
    padding: 1rem 0 2rem 0;

    @include media($screen-sm, max) {
      padding: 2rem 1rem;
    }
  }
  .footer {
    display: none !important;
  }
}
