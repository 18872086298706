.user-follow-button {
  color: $brand-yellow;
  font-size: 0.8rem;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: $brand-sea-green;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  transition: 0.3s ease background-color;
  padding: 0;
  flex-shrink: 0;

  &:hover {
    background-color: darken($brand-sea-green, 10);
  }

  .md-icon {
    width: 22px;
    height: 22px;
  }
}
