.wanderilo-editor-entry {
  width: 100%;
  display: flex;
  align-items: center;

  &__info {
    flex-grow: 1;

    img {
      border-radius: 50%;
      margin-right: 0.5rem;
    }
  }

  &__controls {
    margin-left: 1rem;
    display: flex;
    align-items: center;
  }

  &__control {

    &_primary {
      display: flex;
      color: $brand-sea-green;
      font-weight: 500;
      background-color: darken($brand-gray-lt, 10);
      text-decoration: none;
      padding: 0.2rem 0.5rem;
      text-align: center;
      transition: 0.3s ease background-color;
      min-width: 110px;

      &:hover {
        background-color: darken($brand-gray-lt, 20);
      }

      .md-icon {
        margin-right: 0.5rem;
      }
    }

    &_danger {
      width: 24px;
      height: 24px;
      justify-content: center;
      align-items: center;
      min-width: auto;
      color: $brand-red;
      background-color: white;
      border-radius: 50%;
      padding: 0;

      .md-icon {
        margin: 0;
      }

      &:hover {
        background-color: white;
        color: darken($brand-red, 10);
      }
    }

    &:not(:last-child) {
      margin-bottom: 5px;
    }
  }
}
