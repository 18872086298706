.view-toggle {
  display: flex;
  align-items: center;
  justify-content: center;
  list-style: none;
  margin: 0 0 1rem 0;
  padding: 0;

  &__item {
    font-weight: 600;
    padding: 0.2rem 0.5rem;
    cursor: pointer;
    background-color: darken($brand-gray-lt, 10);
    transition: 0.3s ease background-color;

    &.active {
      background-color: lighten($brand-yellow, 20);
    }

    &:hover {
      background-color: lighten($brand-yellow, 20);
    }

    &:nth-child(1) {
      border-top: 1px solid $brand-sea-green;
      border-bottom: 1px solid $brand-sea-green;
      border-left: 1px solid $brand-sea-green;
    }

    &:nth-child(2) {
      border: 1px solid $brand-sea-green;
    }

    &:nth-child(3) {
      border-top: 1px solid $brand-sea-green;
      border-right: 1px solid $brand-sea-green;
      border-bottom: 1px solid $brand-sea-green;
    }

    &_hidden-sm {

      @include media($screen-sm, max) {
        display: none;
      }
    }
  }
}
