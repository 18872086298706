.tags-list {
  font-size: 0.7rem;
  display: flex;
  list-style-type: none;
  margin: 5px 0;
  padding: 0;
  overflow: hidden;

  @include media($screen-sm, max) {
    overflow-x: scroll;
  }

  &__tag {
    display: inline-block;
    color: black;
    font-weight: 500;
    background-color: #e6e6e6;
    padding: 0.2rem 0.5rem;
    border-radius: 0.2rem;
    flex-shrink: 0;
    white-space: nowrap;

    &:not(:last-child) {
      margin-right: 0.2rem;
    }
  }
}
