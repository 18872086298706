.wander-view {
  position: relative;
  overflow-x: hidden;
  z-index: 10;

  $light-gray: #DFDBE5;

  &__controls {
    display: flex;
  }

  &__map {

    .leaflet-marker-icon {
      border-radius: 50%;
      border: 4px solid $brand-yellow;
    }
  }

  &__panel {
    position: relative;
    z-index: 30;
    width: 100%;
    background-color: white;
    padding: 0;

    @include media($screen-sm, max) {
      padding: 0 0 1rem 0;
    }
  }

  &__title-bar {
    display: grid;
    grid-template-areas: "label label"
                         "user-avatar-link username-link"
                         "user-avatar-link title"
                         "user-avatar-link date"
                         "user-avatar-link category-tags";
    grid-column-gap: 1rem;
    grid-template-columns: 80px 1fr;
    width: 100%;
  }

  .wander-view-title-bar {

    &__label-wrapper {
      display: flex;
      justify-content: flex-end;
      grid-area: label;
    }

    &__label {
      display: flex;
      margin: 0;
      padding: 0;
      list-style: none;

      li {

        &:nth-child(1) {
          color: $brand-sea-green;
          font-weight: 600;
          text-transform: uppercase;
          background-color: $brand-yellow;
          padding: 0.2rem 2rem;
          border: 1px solid darken($brand-yellow, 10);
          border-bottom-left-radius: 0.5rem;
          border-bottom-right-radius: 0.5rem;
          position: relative;
          z-index: 10;
        }

        &:nth-child(2) {
          color: $brand-sea-green;
          font-weight: 600;
          background-color: white;
          padding: 0.2rem 2rem 0.2rem 2.5rem;
          border: 1px solid darken($brand-gray-lt, 10);
          border-bottom-left-radius: 0.5rem;
          border-bottom-right-radius: 0.5rem;
          position: relative;
          z-index: 5;
          margin-left: -0.5rem;
        }
      }
    }

    &__date {
      grid-area: date;
      color: $brand-sea-green;
      font-size: 0.8rem;
      font-weight: 600;
    }
  }

  &__user-avatar-link {
    grid-area: user-avatar-link;
    align-self: center;
  }

  &__username-link {
    grid-area: username-link;
    align-self: center;
    color: $brand-sea-green;
    font-size: 0.8rem;
    font-weight: 600;
    text-decoration: none;
  }

  &__user-avatar {
    border-radius: 50%;
  }

  &__title {
    grid-area: title;
    color: $brand-sea-green;
    display: inline-block;
    margin: 0.5rem 0 0.5rem;
    align-self: start;
    line-height: 1;
    white-space: nowrap;

    @include media($screen-md, max) {
      font-size: 24px;
    }
  }

  &__description-wrapper {
    padding: 1rem;
    background-color: $light-gray;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='32' viewBox='0 0 16 32'%3E%3Cg fill='%239C92AC' fill-opacity='0.14'%3E%3Cpath fill-rule='evenodd' d='M0 24h4v2H0v-2zm0 4h6v2H0v-2zm0-8h2v2H0v-2zM0 0h4v2H0V0zm0 4h2v2H0V4zm16 20h-6v2h6v-2zm0 4H8v2h8v-2zm0-8h-4v2h4v-2zm0-20h-6v2h6V0zm0 4h-4v2h4V4zm-2 12h2v2h-2v-2zm0-8h2v2h-2V8zM2 8h10v2H2V8zm0 8h10v2H2v-2zm-2-4h14v2H0v-2zm4-8h6v2H4V4zm0 16h6v2H4v-2zM6 0h2v2H6V0zm0 24h2v2H6v-2z'/%3E%3C/g%3E%3C/svg%3E");
  }

  &__description {
    max-width: 900px;
    margin: 0 auto;
    padding: 1rem;
    background-color: white;

    h3 {
      color: $brand-gray-md;
      font-size: 2rem;
      font-weight: 400;
      margin: 0;
    }
  }

  &__category-n-tags-wrapper {
    grid-area: category-tags;
    margin: 10px 0;
  }

  &__category {
    display: inline-block;
    color: $brand-yellow;
    background-color: #5c5c5c;
    font-weight: 600;
    font-size: 0.7rem;
    padding: 0.2rem 0.5rem;
  }

  &__wrapper {
    @include wrapper(900px);
    display: flex;
    align-items: flex-end;
    justify-content: space-between;

    @include media($screen-md, max) {
      padding: 0 1rem;
    }

    @include media($screen-sm, max) {
      padding: 0 0.5rem;
      flex-direction: column;
      align-items: flex-start;
    }
  }

  &__places-wrapper {
    max-width: calc(900px + 4rem);
    margin: 0 auto;
    padding: 0 2rem;
    height: 100%;

    @include media($screen-md, max) {
      max-width: calc(900px + 2rem);
      padding: 0 1rem;
    }

    @include media($screen-sm, max) {
      max-width: calc(900px + 1rem);
      padding: 0 0.5rem;
    }
  }

  &__places {
    width: 100%;
    height: 100%;
    max-width: 900px;
    margin: 0;
    padding: 2rem 0;
    position: relative;
    list-style: none;

    &::before {
      content: "";
      display: block;
      width: 4px;
      height: 100%;
      background-color: $brand-sea-green;
      position: absolute;
      z-index: 5;
      transform: translate(14px, -2rem);

      @include media($screen-sm, max) {
        transform: translate(0.7rem, -2rem);
      }
    }
  }

  &__sorter {
    color: white;
    font-weight: bolder;
    margin: 1rem 0 1rem 1rem;
    padding: 0;
    list-style: none;
    display: inline-flex;
    background-color: $brand-sea-green;
    position: relative;
    z-index: 2;

    li {
      display: flex;
      align-items: center;
      cursor: pointer;
      padding: 5px 10px;

      &:hover, &.active {
        background-color: darken($brand-sea-green, 10);
      }
    }
  }

  .wander-view-map {

    .leaflet-map {
      height: 400px;
    }
  }
}
