.login {
  display: grid;
  grid-template-areas: "header" "main" "footer";
  grid-template-rows: 60px 1fr 160px;
  height: 100vh;

  .header {
    grid-area: header;
    background-color: $brand-sea-green;

    &__wrapper {
      grid-area: header;
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      padding: 0 1rem;
    }

    &__logo-wrapper {
      width: 80px;
    }

    &__logo {
      width: 45px;
      height: 45px;
    }

    &__title {
      margin: 0;
      color: $brand-yellow;
      text-transform: uppercase;
    }

    &__widgets {
      min-width: 80px;
      padding-right: 0;
      justify-content: flex-end;

      .user-widget {

        &__link {
          color: $brand-yellow;

          span {
            color: white;
          }
        }
      }
    }
  }

  .header-mobile {

    &__bar {

      .header-mobile-bar-item {

        .current-item {
          background-color: $brand-sea-green;
        }
      }
    }

    &__logo-wrapper {
      width: 45px;
      margin-right: 1rem;
      display: flex;
      align-items: center;
    }

    &__logo {
      width: 45px;
      height: 45px;
      margin-right: 1rem;
    }

    &__title {
      margin: 0 1rem 0 0;
      color: $brand-yellow;
      flex-grow: 1;
    }

    &__widgets {

      .user-widget {

        &__link {
          color: $brand-yellow;
          font-size: 1rem;

          span {
            color: white;
          }
        }
      }
    }

    .header-mobile-toggle {

      &:hover {
        background-color: darken($brand-sea-green, 10);
      }

      svg {

        &.md-icon-plus {
          color: $brand-sea-green;
          background-color: $brand-yellow;
        }
      }
    }
  }

  .main {
    grid-area: main;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 2rem 0;
  }

  .footer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    grid-area: footer;
    background-color: $brand-sea-green;

    &__title {
      color: $brand-yellow;
      font-size: 2rem;
      text-transform: uppercase;
      margin: 0;
    }

    &__copy {
      color: white;
      margin: 0;
    }
  }
}
