.wanderilo-view {
  background-color: white;
  padding-bottom: 2rem;

  &__wrapper {
    @include wrapper();
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    padding: 1rem 0;

    @include media($screen-sm, max) {
      padding: 1rem 0.5rem;
      flex-direction: column;
      align-items: flex-start;
    }
  }

  .breadcrumbs-wrapper {
    background-color: black;
  }

  &__title {
    color: $brand-sea-green;
    background-color: $brand-yellow;
    text-align: center;
    text-transform: uppercase;
    margin: 0;
    padding: 0;
  }

  &__content-wrapper {
    padding: 0 0 4rem 0;
    background-color: white;
  }

  &__image-wrapper {
    margin-bottom: 2rem;
  }

  &__image {
    width: 100%;
    min-height: 600px;
    object-fit: cover;
  }

  &__content {
    @include wrapper();
  }

  .whv-selected-entry {
    position: relative;

    .whv-selected-entry-header {
      display: grid;
      grid-template-areas: "avatar body";
      grid-template-columns: 140px 1fr;
      grid-column-gap: 2rem;
      margin-bottom: 2rem;

      @include media($screen-sm, max) {
        grid-template-areas: "avatar"
                             "body";
        grid-template-columns: 1fr;
      }

      &__avatar {
        grid-area: avatar;
        align-self: center;

        @include media($screen-sm, max) {
          justify-self: center;
        }

        .user-avatar {

          &__link {
            text-decoration: none;
          }

          &__username {
            color: $brand-sea-green;
            text-align: center;
            font-weight: 600;
            margin-bottom: 0;
            margin-top: 4px;
          }
        }
      }

      &__body {
        grid-area: body;
      }

      &__title {
        color: $brand-sea-green;
        font-size: 3rem;
        text-transform: uppercase;
        font-weight: 800;
        text-align: center;
        margin: 0;
      }

      &__description {

      }

      &__tags {
        display: flex;
        justify-content: center;

        .tags-list {
          flex-wrap: wrap;
          justify-content: center;

          @include media($screen-sm, max) {
            max-width: 100%;
          }

          &__tag {
            font-size: 1rem;
            padding: 0.4rem 1rem;
            margin-bottom: 5px;
          }
        }
      }
    }

    .whv-selected-entry__image-wrapper {

    }

    .whv-selected-entry__image {
      width: 100%;
      max-height: 400px;
      object-fit: cover;
    }

    .whv-selected-entry-section {

      &:not(:last-of-type) {
        margin-bottom: 4rem;
      }

      &__counter {
        color: $brand-yellow;
        background-color: $brand-sea-green;
        border-radius: 50%;
        width: 40px;
        height: 40px;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-right: 0.5rem;
      }

      &__title {
        font-size: 2rem;
        display: flex;
        align-items: center;
        color: $brand-sea-green;
      }
    }

    .whv-selected-entry-guides {
      display: grid;
      grid-template-columns: repeat(auto-fill, minmax(320px, 1fr));
      grid-gap: 2rem;

      @include media($screen-sm, max) {

        .wanderilo-guide-item {

          &:not(:first-of-type) {
            display: none;
          }
        }
      }
    }

    .whv-selected-entry-wanders {
      display: grid;
      grid-template-columns: repeat(auto-fill, minmax(320px, 1fr));
      grid-gap: 2rem;

      @include media($screen-sm, max) {

        .wanderilo-wander-item {

          &:not(:first-of-type) {
            display: none;
          }
        }
      }
    }

    .whv-selected-entry-places {
      display: grid;
      grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
      grid-gap: 2rem;

      @include media($screen-sm, max) {

        .wanderilo-place-item {

          &:not(:first-of-type) {
            display: none;
          }
        }
      }
    }

    &__controls {
      @include wrapper();
      display: flex;
      justify-content: flex-end;
    }

    &__control {
      color: $brand-yellow;
      background-color: $brand-sea-green;
      padding: 0.7rem 2.2rem;
      font-size: 1.2rem;
      font-weight: 600;
      transition: 0.3s ease background-color;
      text-decoration: none;
      z-index: 100;
      display: block;

      &:hover {
        background-color: darken($brand-sea-green, 10);
      }
    }
  }
}
