.signup-box {
  color: white;
  background-color: $brand-sea-green;
  border-radius: 0.2rem;

  @include media($screen-sm, max) {
    margin: 0 1rem;
  }

  &__title {
    color: $brand-yellow;
    font-size: 2rem;
    margin-top: -1rem;
    margin-bottom: 1rem;
    text-align: center;
  }

  &__referrer {
    margin-bottom: 1rem;
  }

  .referrer-box {
    display: flex;
    align-items: center;

    &__text {
      margin-left: 10px;
      text-align: center;
      line-height: 1;
      font-weight: 700;
    }
  }

  &__wrapper {
    padding: 2rem;
  }

  &__group {
    display: grid;
    grid-template-columns: 150px 1fr;

    label {
      font-weight: bolder;
    }

    input {
      padding: 0.5rem 1rem;
      border: none;
      width: 100%;
    }

    &:not(:last-child) {
      margin-bottom: 0.5rem;
    }
  }

  &__controls {
    display: flex;
    margin-top: 1rem;

    button {
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: $brand-yellow;
      border: none;
      padding: 0.5rem 1rem;
      flex-grow: 1;
      transition: 0.3s ease background-color;
      cursor: pointer;

      &:hover {
        background-color: darken($brand-yellow, 10);
      }

      svg {
        margin-right: 10px;
      }
    }
  }

  &__content {
    text-align: center;
    margin-top: 1rem;

    p {
      margin: 0;
    }

    a {
      color: $brand-yellow;
      text-decoration: none;
    }
  }

  &__errors {
    font-size: 0.8rem;
    margin: 1rem 0;
    padding: 0 0.5rem;
  }
}
