.guide-manager {
  position: relative;
  z-index: 10;
  margin-top: 3rem;

  &__breadcrumbs {
    @include wrapper();
    margin-top: 1rem;
  }

  &__title {
    @include wrapper();
    color: $brand-sea-green;
    margin: 0 auto;
  }

  &__list {
    width: 100%;
    max-width: $wrapper-max-width;
    margin: 0 auto;
    padding: 1rem 0 2rem 0;

    @include media($screen-sm, max) {
      padding: 2rem 1rem;
    }
  }

  &__placeholder {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%)
  }

  &__subtitle {
    color: rgba(23, 110, 13, 0.53);
    margin-top: 2rem;
  }

  &__separator {
    height: 1px;
    background-color: $brand-sea-green;
  }

}

