.place-list {

  &__placeholder {
    @include wrapper();
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    min-height: 500px;
  }

  &__title {
    font-size: 3rem;
    color: $brand-sea-green;
    margin: 0;
  }

  &__panel {

  }

  .panel {

    &__filers {

    }

    &__view-toggle {

    }
  }

  &__list {
    list-style-type: none;
    margin: 0;
    padding: 0;
  }

  &__item {
    display: flex;
    align-items: center;

    &:not(:last-child) {
      margin-bottom: 0.5rem;
    }

    .place-item-list-view {
      width: 100%;
    }
  }

  &__button {
    color: $brand-yellow;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 48px;
    height: 48px;
    background-color: $brand-sea-green;
    border-radius: 50%;
    z-index: 20;
    transition: 0.3s ease background-color;
    border: 1px solid $brand-yellow;
    margin-right: 1rem;
    padding: 0;

    &:hover {
      background-color: darken($brand-sea-green, 10);
    }
  }
}
