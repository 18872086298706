.map-box {
  position: relative;
  z-index: 10;

  .leaflet-container {
    width: 300px;
    height: 200px;
  }

  .leaflet-touch-zoom {
    pointer-events: none;
  }

  .leaflet-control-attribution {
    display: none;
  }

  .leaflet-control{
    //display: none;
  }
}
