.user-widget {
  display: flex;
  align-items: center;

  @include media($nav-breakpoint, max) {
    flex-direction: column;
  }

  &__button {
    color: white;
    display: flex;
    align-items: center;
    background-color: $brand-sea-green;
    margin-right: 1rem;
    padding: 3px 1rem;
    transition: 0.3s ease background-color;
    font-weight: 400;
    text-decoration: none;
    border-radius: 5px;

    @include media(1200px, max) {
      width: 60px;
      height: 60px;
      padding: 0;
      background: none;
      margin: 0;
      justify-content: center;
      border-radius: 0;

      span {
        display: none;
      }
    }

    &:hover {
      background-color: black;
    }
  }

  &__avatar {
    border-radius: 50%;
    margin-left: 5px;

    @include media($nav-breakpoint, max) {
      margin: 0;
      border: 3px solid $brand-yellow;
    }
  }

  &__link {
    color: $brand-sea-green;
    font-size: 1.1rem;
    font-weight: 600;
    text-decoration: none;
    line-height: 1rem;

    @include media($nav-breakpoint, max) {
      font-size: 0.7rem;
      font-weight: 600;
    }

    span {
      color: black;
      font-weight: bold;
    }
  }

  &__exclamation-sign {
    color: $brand-sea-green;
    width: 50px;
    height: 50px;
    background-color: $brand-yellow;
    border-radius: 50%;
    margin-left: 1rem;
  }

  &__separator {
    color: white;
    margin-left: 5px;
    margin-right: 5px;
  }
}
