.user-profile {
  padding: 0;
  background-color: white;

  &__wrapper {
    @include wrapper(800px);
    padding: 2rem 0;
  }

  &__top-bar {
    display: flex;
    justify-content: flex-end;

    @include media($screen-sm, max) {
      justify-content: center;
      margin-bottom: 1rem;
    }
  }

  &__content-stack {
    display: grid;
    grid-template-areas: "avatar-wrapper content";
    grid-template-columns: 120px 1fr;
    grid-gap: 2rem;

    @include media($screen-sm, max) {
      grid-template-areas: "avatar-wrapper"
                           "content";
      grid-template-columns: 1fr;
    }
  }

  &__top-bar {

  }

  &__wanderilo-button {
    color: darken($brand-gray-md, 10);
    font-size: 1.1rem;
    font-weight: 600;
    display: flex;
    align-items: center;
    min-width: 270px;
    border: 1px solid darken($brand-gray-lt, 10);
    border-radius: 10px;
    padding: 5px;
    text-decoration: none;

    .dot {
      width: 20px;
      height: 20px;
      border-radius: 50%;
      object-fit: cover;
      margin-right: 10px;
    }

    .go {
      width: 30px;
      height: 30px;
      margin-left: 10px;
    }

    span {
      color: $brand-sea-green;
    }
  }

  &__avatar-wrapper {
    grid-area: avatar-wrapper;
    color: $brand-sea-green;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .subscribe-button {
      display: flex;
      align-items: center;
      justify-content: center;
      color: $brand-sea-green;
      background-color: $brand-yellow;
      font-weight: 600;
      margin-top: -1rem;
      min-width: 120px;

      &_active {
        color: $brand-yellow;
        background-color: $brand-sea-green;
      }
    }

    p {
      margin: 0;
      font-weight: 500;
    }
  }

  &__avatar {
    border-radius: 50%;
    object-fit: cover;
    grid-area: avatar;

    @include media($screen-sm, max) {
      margin-right: 0;
    }
  }

  &__content {
    grid-area: content;
    align-self: center;
    color: $brand-sea-green;
  }

  &__username {
    font-family: 'Nunito', sans-serif;
    margin: 0 0 1rem 0;
    line-height: 1;
    font-size: 2.5rem;

    @include media($screen-sm, max) {
      text-align: center;
    }
  }

  &__tagline {
    margin: 0;
    font-size: 1rem;
    line-height: 1.2;
    font-weight: 600;
  }

  &__category-n-tags-wrapper {
    padding: 0.2rem 0;
    display: flex;
    background-color: white;
    width: 100%;
    margin-top: 1rem;
  }

  &__category {
    white-space: nowrap;
    color: white;
    background-color: #5c5c5c;
    font-size: 1rem;
    padding: 0.2rem 0.5rem;
    border-radius: 0.2rem;
    margin: 5px 5px 5px 0;
    font-weight: 600;

    @include media($screen-sm, max) {
      font-size: 11px;
    }
  }

  &__tags {
    width: calc(100% - 2rem);
    margin: 0;

    @include media($screen-sm, max) {
      overflow: hidden;
    }

    .tags-list {
      //justify-content: center;
      white-space: nowrap;

      @include media($screen-sm, max) {
        animation: scrollText 20s linear 3s infinite;
        overflow: visible;
      }

      &__tag {
        font-size: 1rem;
        font-weight: 600;

        @include media($screen-sm, max) {
          font-size: 11px;
        }
      }

      @include media($screen-sm, max) {
        max-width: 100%;
      }
    }
  }

  &__control {
    color: white;
    padding: 0.3rem 1.5rem;
    border-radius: 0.2rem;
    text-decoration: none;
  }

  &__counters {
    background-color: $brand-sea-green;
    padding: 2rem 0;
  }

  &__counters-wrapper {
    @include wrapper();
    display: flex;
    justify-content: space-between;
  }

  &__counter {
    color: $brand-yellow;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1.5rem;
    font-weight: 600;
    padding: 0 50px;

    @include media($screen-sm, max) {
      padding: 0 0.5rem;
    }

    span {
      color: $brand-sea-green;
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: $brand-yellow;
      border-radius: 50%;
      width: 1.6rem;
      height: 1.6rem;
      margin-right: 0.5rem;
    }
  }
}
