.guide-view {
  position: relative;
  z-index: 10;
  background-color: #F8F8F8;

  $light-gray: #DFDBE5;

  .guide-view-map {

    .leaflet-map {
      height: 400px;
    }
  }

  &__map {

    .leaflet-marker-icon {
      border-radius: 50%;
      border: 4px solid $brand-sea-green;
    }
  }

  &__panel {
    position: relative;
    z-index: 30;
    width: 100%;
    background-color: white;
    padding: 0;

    @include media($screen-sm, max) {
      padding: 0 0 1rem 0;
    }
  }

  &__wrapper {
    @include wrapper(900px);
    display: flex;
    align-items: flex-end;
    justify-content: space-between;

    @include media($screen-sm, max) {
      padding: 0 0.5rem;
      flex-direction: column;
      align-items: flex-start;
    }
  }

  &__item {
    width: 100%;
    margin: 0 auto;
    position: relative;

    @include media($screen-sm, max) {
      padding: 0 1rem;
    }

    &_inactive {
      pointer-events: none;
      opacity: 0.5;
    }
  }

  &__title-bar {
    display: grid;
    grid-template-areas: "label label"
                         "user-avatar-link username-link"
                         "user-avatar-link title"
                         "user-avatar-link date"
                         "user-avatar-link category-tags";
    grid-column-gap: 1rem;
    grid-template-columns: 80px 1fr;
    width: 100%;
  }

  .guide-view-title-bar {

    &__label-wrapper {
      display: flex;
      justify-content: flex-end;
      grid-area: label;
    }

    &__label {
      display: flex;
      margin: 0;
      padding: 0;
      list-style: none;

      li {

        &:nth-child(1) {
          color: $brand-sea-green;
          font-weight: 600;
          text-transform: uppercase;
          background-color: $brand-yellow;
          padding: 0.2rem 2rem;
          border: 1px solid darken($brand-yellow, 10);
          border-bottom-left-radius: 0.5rem;
          border-bottom-right-radius: 0.5rem;
          position: relative;
          z-index: 10;
        }

        &:nth-child(2) {
          color: $brand-sea-green;
          font-weight: 600;
          background-color: white;
          padding: 0.2rem 2rem 0.2rem 2.5rem;
          border: 1px solid darken($brand-gray-lt, 10);
          border-bottom-left-radius: 0.5rem;
          border-bottom-right-radius: 0.5rem;
          position: relative;
          z-index: 5;
          margin-left: -0.5rem;
        }
      }
    }

    &__date {
      grid-area: date;
      color: $brand-sea-green;
      font-size: 0.8rem;
      font-weight: 600;
    }
  }

  &__user-avatar-link {
    grid-area: user-avatar-link;
    align-self: center;
  }

  &__username-link {
    grid-area: username-link;
    color: $brand-sea-green;
    font-size: 0.8rem;
    font-weight: 600;
    text-decoration: none;
    margin-top: 0.5rem;
  }

  &__user-avatar {
    border-radius: 50%;
  }

  &__title {
    grid-area: title;
    color: $brand-sea-green;
    display: inline-block;
    align-self: start;
    line-height: 1;
    margin: 0.5rem 0 0.5rem;
  }

  &__cover-image-wrapper {
    width: 100%;
    padding: 1rem 0;
    background-color: lighten($brand-gray-md, 30);
  }

  &__cover-image {
    max-height: 500px;
    object-fit: cover;
  }

  &__description-wrapper {
    padding: 1rem;
    background-color: $light-gray;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='32' viewBox='0 0 16 32'%3E%3Cg fill='%239C92AC' fill-opacity='0.14'%3E%3Cpath fill-rule='evenodd' d='M0 24h4v2H0v-2zm0 4h6v2H0v-2zm0-8h2v2H0v-2zM0 0h4v2H0V0zm0 4h2v2H0V4zm16 20h-6v2h6v-2zm0 4H8v2h8v-2zm0-8h-4v2h4v-2zm0-20h-6v2h6V0zm0 4h-4v2h4V4zm-2 12h2v2h-2v-2zm0-8h2v2h-2V8zM2 8h10v2H2V8zm0 8h10v2H2v-2zm-2-4h14v2H0v-2zm4-8h6v2H4V4zm0 16h6v2H4v-2zM6 0h2v2H6V0zm0 24h2v2H6v-2z'/%3E%3C/g%3E%3C/svg%3E");
  }

  &__description {
    max-width: 900px;
    margin: 0 auto;
    padding: 1rem;
    background-color: white;

    h3 {
      color: $brand-gray-md;
      font-size: 2rem;
      font-weight: 400;
      margin: 0;
    }
  }

  &__category-n-tags-wrapper {
    grid-area: category-tags;
    margin: 10px 0;
  }

  &__category {
    display: inline-block;
    color: $brand-yellow;
    background-color: #5c5c5c;
    font-weight: 600;
    font-size: 0.7rem;
    padding: 0.2rem 0.5rem;
  }

  &__sidebar {
    position: fixed;
    width: 380px;
    height: 100%;
    margin-right: -380px;
    top: 170px;
    right: 0;
    z-index: 20;
    transition: 0.3s ease margin-right;

    &_active {
      margin-right: 0;
    }
  }

  .navigation {

  }

  .chapter-navigation {
    position: absolute;
    right: 10%;
    top: 0;
    z-index: 50;
    margin-top: 2rem;

    @include media($screen-sm, max) {
      right: 1rem;
      top: 0;
    }

    &__list {
      //position: sticky;
      //top: 252px;
      list-style: none;
      margin: 0;
      padding: 0;
    }

    &__item {
      display: flex;
      align-items: center;

      &:not(:last-child) {
        margin-bottom: 0.2rem;
      }
    }

    &__chapter {
      background-color: $brand-sea-green;
      padding: 0.2rem;
      cursor: pointer;
      position: relative;

      img, .map-box, .leaflet-container {
        width: 60px;
        height: 60px;
        pointer-events: none;
      }

      .leaflet-control-container {
        display: none;
      }
    }

    &__number {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      color: white;
      font-size: 2rem;
      font-weight: 800;
      z-index: 30;
      background-color: rgba(23, 110, 13, 0.3);
    }
  }

  .sidebar {

    &__tab {
      display: flex;
      flex-direction: column;
      position: sticky;
      top: 100px;
      left: 0;
      width: 100%;
      background-color: white;
      border: 1px solid $brand-sea-green;
    }

    .sidebar-tab {

      &__title {
        font-size: 2.5rem;
        display: flex;
        align-items: center;
        justify-content: center;
        color: $brand-sea-green;
        margin: 0;
        min-height: 80px;
        border-bottom: 1px solid $brand-gray-md;
      }

      &__guide-title {
        padding: 1rem;
        margin: 1rem 1rem 0 1rem;
        border: none;
        font-size: 1.4rem;
      }

      &__list {
        padding: 1rem;
        flex-grow: 1;
      }

      &__controls {
        display: flex;
        justify-content: space-around;
        flex-wrap: wrap;
        align-items: center;
        transform: translateY(60px);
        opacity: 0;
        transition: 0.3s ease transform, 0.3s ease opacity;
        min-height: 80px;
        border-top: 1px solid $brand-gray-md;

        .button-group {
          display: flex;
          justify-content: space-between;
          width: 100%;
          padding: 0.5rem 1rem;
        }

        &_active {
          transform: translateY(0);
          opacity: 1;
        }

        button {
          color: $brand-yellow;
          font-weight: 600;
          font-size: 1.1rem;
          padding: 0.5rem 1rem;
          border: none;
          background-color: $brand-sea-green;
          cursor: pointer;
          transition: 0.3s ease background-color;

          &:hover {
            background-color: darken($brand-sea-green, 10);
          }
        }
      }

      &__label {
        position: absolute;
        transform: rotate(-90deg) translateX(-50%);
        display: flex;
        align-items: center;
        justify-content: center;
        top: 0;
        left: -7rem;
        background-color: $brand-sea-green;
        color: $brand-yellow;
        font-size: 1.2rem;
        font-weight: 500;
        padding: 0.5rem;
        border-top-left-radius: 10px;
        border-top-right-radius: 10px;
        height: 100%;
      }

      .tab-label {

        &__button {
          display: flex;
          align-items: center;
          font-size: 1.2rem;
          font-weight: 500;
          padding: 0.5rem;
          color: $brand-yellow;
          border: none;
          background: none;
          cursor: pointer;

          .md-icon {
            margin-left: 0.5rem;
          }
        }
      }
    }
  }

  .guide-place-list {
    list-style: none;
    margin: 0;
    padding: 0;

    &__item {

      .map-box {

        .leaflet-container {
          width: 100%;
          height: 300px;
        }

        .leaflet-touch-zoom {
          pointer-events: none;
        }
      }
    }
  }

  .guide-place-list-item {
    position: relative;
    margin-bottom: 2rem;

    &__anchor {
      position: absolute;
      top: -200px;
    }

    &__box {

      @include media($screen-sm, max) {
        margin-right: 80px;
      }
    }

    &__content {
      margin-top: 1rem;
    }

    &__preview-wrapper {
      background-color: lighten($brand-gray-md, 30);
      min-height: 300px;
    }

    &__preview {
      max-width: 900px;
      margin: 0 auto 1rem auto;

      img {
        max-height: 500px;
        object-fit: cover;
      }
    }

    &__content-wrapper {
      max-width: 900px;
      margin: 0 auto;
    }

    &__separator {
      display: block;
      border: none;
      height: 1px;
      background-color: lighten($brand-gray-md, 30);
      margin: 2rem 0;
    }

    &__chapter-wrapper {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }

    &__chapter-title-wrapper {
      display: flex;
      align-items: center;

      .user-avatar {
        margin-right: 0.5rem;
        border-radius: 50%;
      }
    }

    &__chapter-title {
      color: $brand-gray-md;
      font-size: 2rem;
      font-weight: 400;
      margin: 0;
    }

    &__controls {
      margin-left: 1rem;
    }

    &__control {
      color: $brand-yellow;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 40px;
      height: 40px;
      background-color: $brand-sea-green;
      border-radius: 50%;
      z-index: 10;
      transition: 0.3s ease background-color;
      padding: 0;

      &:hover {
        background-color: darken($brand-sea-green, 10);
      }

      svg {
        flex-grow: 1;
        flex-shrink: 0;
      }
    }

    &__title {
      color: $brand-sea-green;
      font-size: 3rem;
      margin: 0;
      max-width: 50%;
      line-height: 3rem;

      @include media($screen-sm, max) {
        font-size: 2rem;
        line-height: 1;
        max-width: 100%;
      }
    }

    &__controls-list {
      margin: 1rem 0;
      padding: 0;
      list-style: none;
      display: flex;
      align-items: center;

      li {
        display: flex;
        align-items: center;
        color: $brand-sea-green;
        font-weight: 600;
        position: relative;

        a {
          color: $brand-sea-green;
        }

        &:not(:last-child) {
          margin-right: 0.5rem;
        }

        &:nth-child(2) {
          padding-right: 0.5rem;

          &::after {
            display: block;
            content: "";
            height: 20px;
            width: 2px;
            background-color: $brand-sea-green;
            position: absolute;
            top: 6px;
            right: 0;
          }
        }
      }

      .location-icon {
        width: 20px;
        height: 30px;
        margin-right: 0.5rem;

        path {
          fill: $brand-sea-green;
        }
      }
    }

    &__coordinates {

    }

    &__images {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
      grid-gap: 2rem;
      margin-top: 2rem;
    }

    &__description {

    }
  }
}
