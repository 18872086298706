.wanderilo-wander-item {
  display: flex;
  flex-direction: column;

  &__image-wrapper {
    border-bottom: 5px solid $brand-yellow;
    height: 160px;
    overflow: hidden;
    border-top-left-radius: 1rem;
    border-top-right-radius: 1rem;
    position: relative;
    z-index: 1;

    .map-box {

      .leaflet-container {
        width: 100%;
      }
    }
  }

  &__image {
    object-fit: cover;
  }

  &__map {

    .leaflet-map {
      height: 160px;
    }

    .leaflet-marker-icon {
      border-radius: 50%;
      border: 4px solid $brand-yellow;
    }
  }

  &__content {
    display: flex;
    flex-direction: column;
    position: relative;
    border-left: 1px solid $brand-yellow;
    border-right: 1px solid $brand-yellow;
    border-bottom: 1px solid $brand-yellow;
    flex-grow: 1;
    border-bottom-left-radius: 1rem;
    border-bottom-right-radius: 1rem;
  }

  &__label-wrapper {
    margin-left: 1rem;
  }

  &__label {
    display: flex;
    margin: 0;
    padding: 0;
    list-style: none;

    li {

      &:nth-child(1) {
        color: $brand-sea-green;
        font-size: 12px;
        font-weight: 600;
        text-transform: uppercase;
        background-color: $brand-yellow;
        padding: 0.2rem 2rem;
        border: 1px solid $brand-yellow;
        border-bottom-left-radius: 0.5rem;
        border-bottom-right-radius: 0.5rem;
        position: relative;
        z-index: 10;
      }

      &:nth-child(2) {
        color: $brand-sea-green;
        font-size: 11px;
        font-weight: 600;
        background-color: white;
        padding: 0.2rem 2rem 0.2rem 2.5rem;
        border: 1px solid darken($brand-gray-lt, 10);
        border-bottom-left-radius: 0.5rem;
        border-bottom-right-radius: 0.5rem;
        position: relative;
        z-index: 5;
        margin-left: -0.5rem;
      }
    }
  }

  &__title {
    display: inline-block;
    color: $brand-sea-green;
    font-size: 1.3rem;
    line-height: 1.2rem;
    font-weight: 600;
    margin: 1rem 1rem 0.5rem 1rem;
    padding: 0;
  }

  &__user-info {
    display: flex;
    margin: 0 1rem;
    align-items: center;
    font-size: 0.8rem;
    color: $brand-sea-green;
    font-weight: 600;

    a {
      text-decoration: none;
    }
  }

  &__user-avatar {
    margin: 0 0.3rem;
    border-radius: 50%;
  }

  &__counter {
    background-color: $brand-yellow;
    color: $brand-sea-green;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 14px;
    font-weight: 600;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    position: absolute;
    top: -15px;
    right: 1rem;
    z-index: 10;
  }

  &__panel {
    position: relative;
  }

  &__save-button-wrapper {
    top: 1rem;
    right: 1rem;
    z-index: 20;
    position: absolute;
  }

  &__save-button {
    color: $brand-yellow;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 48px;
    height: 48px;
    background-color: $brand-sea-green;
    border-radius: 50%;
    z-index: 10;
    transition: 0.3s ease background-color;
    border: 1px solid $brand-yellow;

    &:hover {
      background-color: darken($brand-sea-green, 10);
    }
  }

  &__description {
    margin: 1rem;
    font-size: 0.8rem;
    color: $brand-sea-green;
    flex-grow: 1;
  }

  &__category-n-tags-wrapper {
    padding: 0.5rem 1rem;
    border-top: 1px solid darken($brand-gray-lt, 10);
    display: flex;
    margin-top: 0.5rem;
  }

  &__category {
    white-space: nowrap;
    color: white;
    background-color: #5c5c5c;
    font-size: 0.8rem;
    padding: 0.2rem 0.5rem;
    border-radius: 0.2rem;
    margin: 5px 5px 5px 0;
  }

  &__tags {
    width: calc(100% - 2rem);
    overflow: hidden;

    .tags-list {
      justify-content: center;
      white-space: nowrap;
      animation: scrollText 20s linear 3s infinite;
      overflow: visible;

      &__tag {
        font-size: 0.8rem;
      }

      @include media($screen-sm, max) {
        max-width: 100%;
      }
    }
  }
}
