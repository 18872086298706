.wander-view-entry {
  display: grid;
  grid-template-areas: "label wrapper";
  grid-template-columns: min-content 1fr;
  position: relative;
  z-index: 10;
  margin: 1rem 0;

  &__wrapper {
    color: $brand-sea-green;
    display: grid;
    grid-area: wrapper;
    grid-template-areas: "image info";
    grid-template-columns: 100px 1fr;
    background-color: white;
    border: 1px solid lighten($brand-gray-md, 10);

    @include media($screen-md, max) {
      padding: 0;
      grid-template-areas: "image info";
      grid-template-columns: 100px 1fr;

      .wander-editor-entry__controls {
        display: none;
      }
    }
  }

  &__user-info {

  }

  &__image {
    grid-area: image;
    width: 100px;
    height: 100%;
    object-fit: cover;
  }

  &__content {
    display: flex;
    flex-direction: column;
    grid-area: content;
  }

  &__card {
    grid-area: info;
    padding: 0.5rem;

    @include media($screen-sm, max) {
      margin-left: calc(-40px - 1rem);
    }
  }

  .wander-view-entry-card {
    display: flex;

    &__info {
      margin-left: 0.5rem;
    }

    &__header {
      color: $brand-sea-green;
      font-size: 1.3rem;
      line-height: 1.4rem;
      font-weight: 700;
      text-decoration: none;
      margin: 0;
      word-wrap: break-word;

      @include media($screen-sm, max) {
        font-size: 0.9rem;
      }
    }

    &__user-avatar-wrapper {
      display: flex;
      align-items: center;
    }

    &__username {
      font-size: 1rem;
      line-height: 1;
      margin: 0;
      font-weight: 600;

      @include media($screen-sm, max) {
        font-size: 0.9rem;
      }
    }

    &__timestamp-wrapper {
      margin-top: -0.5rem;

      @include media($screen-md, max) {
        margin-top: 0;
      }
    }

    &__timestamp {
      font-size: 0.8rem;
      display: table-cell;
      padding: 0 10px 3px 10px;
      background-color: $brand-yellow;
      border-bottom-left-radius: 10px;
      border-bottom-right-radius: 10px;
      white-space: nowrap;

      @include media($screen-md, max) {
        font-size: 0.6rem;
      }
    }

    &__category-wrapper {
      display: grid;
      grid-template-columns: auto 1fr;
      grid-gap: 5px;
    }

    &__category {
      display: inline-block;
      color: white;
      background-color: #5c5c5c;
      font-weight: 600;
      font-size: 0.7rem;
      padding: 0.2rem 0.5rem;
      margin: 5px 0;
    }
  }

  .map-box {
    width: 100%;
    height: 100%;

    .leaflet-container {
      width: 100%;
      height: 100%;
    }

    .leaflet-control-container {
      display: none;
    }
  }
}
