.latest-guides-item {
  background-color: $brand-sea-green;

  &__label {
    display: flex;
    align-items: center;
    color: $brand-yellow;
    font-size: 1rem;
    min-height: 30px;

    @include media($screen-sm, max) {
      font-size: 0.8rem;
      min-height: auto;
      line-height: 1;
    }

    a {
      text-decoration: none;
    }

    .user-follow-button {
      margin-left: 1rem;
      border: 1px solid darken($brand-sea-green, 10);
      background-color: darken($brand-sea-green, 3);

      &:hover {
        background-color: darken($brand-sea-green, 10);
      }
    }
  }

  &__title {
    color: white;
    margin: 0;
    font-size: 1.2rem;
    font-weight: 900;
    text-decoration: none;

    @include media($screen-sm, max) {
      line-height: 1;
      font-size: 1rem;
    }
  }

  &__preview {
    display: block;
    height: 300px;
    overflow: hidden;

    .map-box {
      width: 100%;
      height: 100%;

      .leaflet-container {
        width: 100%;
        min-height: 300px;
      }
    }
  }

  &__image {

  }

  &__user-avatar {
    position: absolute;
    top: 50%;
    left: 1rem;
    transform: translateY(-50%);
    border-radius: 50%;
  }

  &__content {
    padding: 1rem 80px 1rem 100px;
    position: relative;
  }

  &__save-button-wrapper {
    position: absolute;
    top: 50%;
    right: 1rem;
    transform: translateY(-50%);
  }

  &__save-button {
    color: $brand-yellow;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 48px;
    height: 48px;
    background-color: $brand-sea-green;
    border-radius: 50%;
    z-index: 10;
    transition: 0.3s ease background-color;
    border: 1px solid $brand-yellow;

    &:hover {
      background-color: darken($brand-sea-green, 10);
    }
  }

  &__controls {
    display: flex;
    padding: 5px;
  }

  &__control {
    display: flex;
    align-items: center;
    flex-grow: 1;
    color: $brand-yellow;
    border: 1px solid $brand-yellow;
    border-radius: 4px;
    text-decoration: none;

    &:not(:last-child) {
      margin-right: 5px;
    }

    svg {
      margin: 0 5px;
    }
  }
}
