.spotlist-place-item {
  display: grid;
  grid-template-columns: minmax(220px, 320px) 1fr;
  border: 1px solid lighten($brand-gray-md, 20);
  border-radius: 5px;

  @include media($screen-sm, max) {
    grid-template-columns: 1fr;
  }

  &:not(:last-of-type) {
    margin-bottom: 2rem;
  }

  &__map {
    padding: 5px;

    .map-box {
      width: 100%;
      height: 100%;
      min-height: 220px;

      .leaflet-container {
        width: 100%;
        height: 100%;
        min-width: 100px;
        min-height: 100px;
        max-height: 220px;
      }

      .leaflet-control-container {
        display: none;
      }
    }

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      max-height: 220px;
    }
  }

  &__box {
    padding: 1rem;
  }

  &__category-n-tags-wrapper {
    padding: 0.2rem 0;
    display: flex;
    background-color: white;
    width: 100%;
    margin-top: 1rem;
  }

  &__category {
    white-space: nowrap;
    color: white;
    background-color: $brand-sea-green;
    font-size: 0.8rem;
    padding: 0.2rem 0.5rem;
    border-radius: 0.2rem;
    margin: 5px 5px 5px 0;
    font-weight: 600;

    @include media($screen-sm, max) {
      font-size: 11px;
    }
  }

  &__tags {
    width: calc(100% - 2rem);
    margin: 0;

    @include media($screen-sm, max) {
      overflow: hidden;
    }

    .tags-list {
      //justify-content: center;
      white-space: nowrap;

      @include media($screen-sm, max) {
        animation: scrollText 20s linear 3s infinite;
        overflow: visible;
      }

      &__tag {
        font-size: 0.8rem;
        font-weight: 600;
        background-color: $brand-yellow;

        @include media($screen-sm, max) {
          font-size: 11px;
        }
      }

      @include media($screen-sm, max) {
        max-width: 100%;
      }
    }
  }

  .spi-controls {
    display: flex;
    border: 1px solid lighten($brand-gray-md, 20);
    background-color: $brand-gray-lt;
    border-radius: 5px;
    list-style: none;
    padding: 0;
    margin: 0 auto;
    max-width: 400px;

    &__button-wrapper {
      flex-grow: 1;
    }

    &__button {
      color: $brand-sea-green;
      display: flex;
      align-items: center;
      font-size: 0.8rem;
      flex-grow: 1;
      text-decoration: none;
      padding: 5px 10px;

      &:hover {
        background-color: darken($brand-gray-lt, 10);
      }
    }
  }

  &__place-title {
    display: flex;
    align-items: center;
    color: $brand-sea-green;
    font-size: 1.4rem;
    font-weight: 600;
    line-height: 1;
    margin-top: 1rem;
  }

  &__place-description {
    color: $brand-sea-green;
    font-size: 0.9rem;
    margin-top: 1rem;
  }

  &__user-avatar {
    border-radius: 50%;
    border: 4px solid #fbcc2b;
    margin-right: 1rem;
  }
}
