@import "swiper/swiper.min.css";
@import "swiper/swiper-bundle.min.css";

.latest-guides-list {
  padding: 0;
  margin: 2rem 0;

  @include media($screen-sm, max) {
    padding: 0 0.5rem;
  }

  &__wrapper {
    margin: 0;
  }

  &__list {
    @include wrapper();
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(320px, 1fr));
    grid-gap: 2rem;
    margin-top: 2rem;
    margin-bottom: 2rem;
    padding: 0;
    list-style: none;
  }

  &__item {
    max-width: 480px;
  }
}
