.place-editor {
  position: relative;
  z-index: 10;
  display: flex;
  margin: 2rem 0;

  @include media($screen-sm, max) {
    padding: 0 1rem;
  }

  &__title {
    grid-area: title;
    color: $brand-sea-green;
    display: inline-block;
    margin: 0 0 1rem 0;
    align-self: start;
    line-height: 1;
    font-size:1.5rem
  }

  .address-bar {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 0.5rem;
    margin-top: 1rem;

    &__latitude, &__longitude {

    }

    &__type {
      grid-column-start: 1;
      grid-column-end: 3;
    }

    input {
      padding: 0.5rem;
    }
  }

  &__category-tags-wrapper {
    display: grid;
    grid-template-columns: 70px 1fr 1fr;
    grid-gap: 1rem;

    @include media($screen-sm, max) {
      grid-template-columns: 1fr;
    }
  }

  &__price-selector {

    .ember-power-select-placeholder {
      color: $brand-red;
      font-weight: 600;

      .ember-power-select-option {
        color: $brand-red;
        font-weight: 600;
      }
    }

    .ember-power-select-selected-item {
      color: $brand-red;
      font-weight: 600;
    }
  }

  &__category-selector, &__tag-selector {

    .emberTagInput {
      padding: 0;
      border: none;

      .emberTagInput-new {

        input {
          padding-top: 4px;
          padding-bottom: 3px;
        }
      }
    }
  }

  &__wrapper {
    width: 100%;
    max-width: 1200px;
    margin: 0 auto;
  }

  &__module {

    &:not(:last-child) {
      margin-bottom: 0.5rem;
    }
  }

  .map-box {
    margin: 1rem 0;

    @include media($screen-sm, max) {
      height: 300px;
    }

    .leaflet-container {
      width: 100%;
      height: 300px;
      pointer-events: all;
    }
  }

  .place {

    &__name {
      padding: 0.5rem;
      border: none;
      font-size: 1.2rem;
      width: 100%;
    }

    &__geocoder {
      margin-bottom: 1rem;
    }

    &__address {
      border: none;
      width: 100%;
      margin: 1rem 0;
    }

    &__geocode-btn {
      display: flex;
      align-items: center;
      border-radius: 0.2rem;
      color: white;
      padding: 0 1rem;
      border: none;
      background-color: $brand-sea-green-lt;
      cursor: pointer;
      transition: 0.3s ease background-color;

      svg {
        margin-left: 0.5rem;
      }

      &:hover {
        background-color: darken($brand-sea-green-lt, 10);
      }
    }

    &__description {

    }
  }

  &__controls {
    width: 100%;
    margin-top: 1rem;

    button {
      @include button;
      display: flex;
      align-items: center;
      color: black;
      font-size: 1.2rem;
      background-color: $brand-yellow;
      padding: 0.5rem 2rem;
      transition: 0.3s ease background-color;
      margin-left: auto;

      svg {
        color: $brand-sea-green;
        margin-right: 10px;
      }

      &:hover {
        background-color: darken($brand-yellow, 10);
      }
    }
  }

  .toastui-editor-mode-switch {
    display: none !important;
  }
}
